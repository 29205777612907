<template>
<div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
            <b-col xl="12" class="mb-5">
                <b-overlay :show="cargarEvaluacion" rounded="lg" opacity="0.5" spinner-variant="default">
                    <template v-slot:overlay>
                        <div class="d-flex align-items-center">
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                            <b-spinner type="grow" variant="dark"></b-spinner>
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                        </div>
                    </template>
                    <card header-bg-variant="info" header-classes="bg-transparent">
                        <b-row align-v="center" slot="header">
                            <b-col>
                                <h3 class="mb-0">Agregar Evaluación </h3>
                            </b-col>
                        </b-row>
                        <!--    <h6 class="heading-small text-muted mb-4">User information</h6>-->
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.stop.prevent="handleSubmit(RegistrarEvaluacion)">
                                <div class="pl-lg-3 pr-lg-3 ">
                                    <b-row>

                                        <b-col lg="4">
                                            <base-input label="Nombre" name="de nombre" :rules="{ required: true }" placeholder="Ingrese nombre" v-model="datosEvaluacion.nombre" input-classes="form-control-sm" class="mb-1"> </base-input>
                                        </b-col>
                                        <b-col lg="4">
                                            <base-input label="Descripción" name="de descripción" placeholder="Ingrese descripción" v-model="datosEvaluacion.descripcion" input-classes="form-control-sm"> </base-input>
                                        </b-col>
                                        <b-col lg="4">
                                            <base-input label="Preguntas disponibles" name="de preguntas disponibles" :rules="{ numeric:true,required: true }" placeholder="Ingrese cantidad de preguntas disponibles" v-model="datosEvaluacion.n_preguntas" input-classes="form-control-sm"> </base-input>
                                        </b-col>

                                        <b-col lg="12 text-right">
                                            <b-button size="sm" variant="success" class="mb-2" type="submit">
                                                <b-icon icon="journal-plus" aria-label="Help"></b-icon> Registrar Evaluación
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-form>
                        </validation-observer>
                    </card>
                </b-overlay>
            </b-col>
        </b-row>
    </base-header>

    <!--formulario-->
    <b-container fluid class="mt--8 mb-5">
        <b-row>
            <b-col xl="12">
                <b-overlay :show="cargarEvaluacion" rounded="lg" opacity="0.5" spinner-variant="default">
                    <template v-slot:overlay>
                        <div class="d-flex align-items-center">
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                            <b-spinner type="grow" variant="dark"></b-spinner>
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                        </div>
                    </template>
                    <card header-bg-variant="info" header-classes="bg-transparent">
                        <b-row align-v="center" slot="header">
                            <b-col>
                                <h3 class="mb-0">Evaluaciones</h3>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="4">
                                <base-input v-if="!cargarEvaluacion">
                                    <b-input-group class="text-right" size="xl">
                                        <b-input-group-prepend is-text>
                                            <b-icon icon="search"></b-icon>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </base-input>
                            </b-col>
                            <b-col lg="4">
                            </b-col>
                            <b-col lg="4">
                                <base-input v-if="!cargarEvaluacion">
                                    <b-form-select size="xl" v-model="porPagina" id="porPaginaSelect" :options="paginaOpciones"></b-form-select>
                                </base-input>
                            </b-col>
                            <b-col lg="12">
                                <b-table sort-icon-left show-empty small responsive outlined :items="listaEvaluaciones" :fields="camposEvaluacion" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay evaluaciones en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(actions)="param">
                                        <base-button icon size="sm" type="default" @click="CargarDatosModificables(param)">
                                            <span class="btn-inner--icon">
                                                <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                                            </span>
                                            <span class="btn-inner--text">Modificar</span>
                                        </base-button>
                                        <base-button icon size="sm" type="warning" @click="CargarDatosModificablesPregunta(param)">
                                            <span class="btn-inner--icon">
                                                <b-icon icon="command" aria-label="Help"></b-icon>
                                            </span>
                                            <span class="btn-inner--text">Asignar Preguntas</span>
                                        </base-button>
                                        <!--      <template v-if="param.item.estado == 2">
                                        <base-button icon size="sm" type="danger" @click="AnularEvaluacion(param)">
                                            <span class="btn-inner--icon">
                                                <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                                            </span>
                                            <span class="btn-inner--text">Anular</span>
                                        </base-button>

                                    </template>
                                    <template v-else>
                                        <base-button icon size="sm" type="success" @click="RestaurarEvaluacion(param)">
                                            <span class="btn-inner--icon">
                                                <b-icon icon="arrow-counterclockwise" aria-label="Help"></b-icon>
                                            </span>
                                            <span class="btn-inner--text">Restaurar</span>
                                        </base-button>
                                    </template>-->
                                    </template>

                                    <template v-slot:cell(estado)="data">
                                        <b class="text-danger" v-if="data.value=='1'">INACTVO</b>
                                        <b class="text-success" v-else>ACTIVO</b>
                                    </template>
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <template v-slot:footer>
                            <b-row v-if="!cargarEvaluacion">
                                <b-col lg="12">
                                    <b-pagination style="justify-content:flex-end;" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                                </b-col>
                            </b-row>
                        </template>
                    </card>
                </b-overlay>
            </b-col>
        </b-row>
    </b-container>
    <!--End formulario-->

    <b-modal id="modal-modificar-evaluacion" ref="modal-modificar-evaluacion" title="Actualizar Información" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(ActualizarEvaluacion)">
                <div class="pl-lg-3 pr-lg-3 ">
                    <b-row>
                        <b-col lg="4">
                            <base-input label="Nombre" name="de nombre" :rules="{ required: true }" placeholder="Ingrese nombre" v-model="modifEvaluacion.nombre" input-classes="form-control-sm" class="mb-1"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Descripción" name="de descripción" placeholder="Ingrese descripción" v-model="modifEvaluacion.descripcion" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Preguntas disponibles" name="de preguntas disponibles" :rules="{ numeric:true,required: true }" placeholder="Ingrese cantidad de preguntas disponibles" v-model="modifEvaluacion.n_preguntas" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="12  text-right">
                            <b-button size="sm" variant="success" class="mb-2" type="submit">
                                Actualizar
                            </b-button>
                            <b-button class="mb-2" size="sm" variant="danger" @click="$bvModal.hide('modal-modificar-evaluacion')">Cerrar</b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>

    <b-modal id="modal-agregar-pregunta" ref="modal-agregar-pregunta" title="Agregar pregunta" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(RegistrarPregunta)">
                <div class="pl-lg-3 pr-lg-3 ">
                    <b-row>
                        <b-col lg="4">
                            <base-input label="Enunciado" name="de enunciado" :rules="{ required: true }" placeholder="Ingrese enunciado" v-model="datosPregunta.enunciado" input-classes="form-control-sm" class="mb-1"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <validation-provider name="de tipo de pregunta" :rules="{ required: true }" v-slot="validationContext">
                                <base-input label="Tipo de Pregunta">
                                    <b-form-select disabled :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" v-model="datosPregunta.tipoPregunta" :options="optionsTipoPregunta" size="sm">
                                    </b-form-select>
                                    <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </base-input>
                            </validation-provider>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Pregunta A" name="de pregunta a" :rules="{ required: true }" placeholder="Ingrese pregunta a" v-model="datosPregunta.preguntaA" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Pregunta B" name="de pregunta b" :rules="{ required: true }" placeholder="Ingrese pregunta b" v-model="datosPregunta.preguntaB" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Pregunta C" name="de pregunta c" :rules="{ required: true }" placeholder="Ingrese pregunta c" v-model="datosPregunta.preguntaC" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <validation-provider name="de respuesta" :rules="{ required: true }" v-slot="validationContext">
                                <base-input label="Respuesta">
                                    <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" v-model="datosPregunta.respuesta" :options="options" size="sm">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </base-input>
                            </validation-provider>
                        </b-col>
                        <b-col lg="12  text-right">
                            <b-button size="sm" variant="success" class="mb-2" type="submit">
                                Agregar
                            </b-button>
                            <b-button class="mb-2" size="sm" variant="danger" @click="$bvModal.hide('modal-agregar-pregunta')">Cerrar</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-table :items="listaPreguntas" class="mt-2" small outlined sticky-header="250px" :fields="camposPreguntas" responsive show-empty empty-text="No se encontraron preguntas">
                            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                            <template v-slot:cell(vermas)="row">
                                <base-button icon size="sm" type="default" @click="row.toggleDetails">
                                    <span class="btn-inner--icon">
                                        <b-icon :icon="row.detailsShowing ? 'eye-slash-fill' : 'eye-fill'" aria-label=" Help"></b-icon>
                                    </span>
                                    <span class="btn-inner--text">{{ row.detailsShowing ? 'Ocultar' : 'Mostrar'}} Detalles</span>
                                </base-button>

                            </template>

                            <template v-slot:row-details="row">
                                <b-card class="bg-secondary">
                                    <b-row class="mb-2">
                                        <b-col sm="2" class="text-sm-right"><b>Opción a): </b></b-col>
                                        <b-col>{{ row.item.opcionA }}</b-col>
                                    </b-row>
                                    <b-row class="mb-2">
                                        <b-col sm="2" class="text-sm-right"><b>Opción b): </b></b-col>
                                        <b-col>{{ row.item.opcionB }}</b-col>
                                    </b-row>
                                    <b-row class="mb-2">
                                        <b-col sm="2" class="text-sm-right"><b>Opción c): </b></b-col>
                                        <b-col>{{ row.item.opcionC  }}</b-col>
                                    </b-row>
                                    <b-row class="mb-2">
                                        <b-col sm="3" class="text-sm-right"><b>La respuesta Correcta es la opción: {{ row.item.respuesta}})</b></b-col>
                                    </b-row>
                                    <b-row class="mb-2">
                                        <b-col lg="12" sm="3" class="text-sm-left">
                                            <base-button v-b-tooltip title="Quitar Pregunta" icon size="sm" type="danger" @click="QuitarPregunta(row)">
                                                <span class="btn-inner--icon">
                                                    <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                                                </span>
                                                <span class="btn-inner--text">Eliminar Pregunta </span>
                                            </base-button>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </template>
                        </b-table>
                    </b-row>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            optionsTipoPregunta: [{
                    value: '1',
                    text: 'Multiple opciones'
                },

            ],
            options: [{
                    value: 'a',
                    text: 'Opción A'
                },
                {
                    value: 'b',
                    text: 'Opción B'
                },
                {
                    value: 'c',
                    text: 'Opción C'
                },
            ],
            cargarEvaluacion: false,
            cargarPreguntas: false,
            listaEvaluaciones: [],
            listaPreguntas: [],
            datosEvaluacion: {
                nombre: "",
                descripcion: "",
                n_preguntas: "",
            },
            datosPregunta: {
                idevaluacion: "",
                enunciado: "",
                tipoPregunta: 1,
                preguntaA: "",
                preguntaB: "",
                preguntaC: "",
                respuesta: null
            },
            modifEvaluacion: {
                idEvaluacion: "",
                nombre: "",
                descripcion: "",
                n_preguntas: "",
            },

            camposEvaluacion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center"
                },
                {
                    key: "fecha_creacion",
                    label: "Fecha",
                    class: "text-center"
                },
                {
                    key: "n_preguntas",
                    label: "Preguntas disponibles",
                    class: "text-center"
                },
                {
                    key: "estado",
                    label: "Estado",
                    class: "text-center"
                },
                {
                    key: "actions",
                    label: "opciones",
                    class: "text-center"
                },
            ],
            camposPreguntas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center ",
                },
                {
                    key: "enunciado",
                    label: "enunciado",
                    class: "text-center",
                },
                {
                    key: "respuesta",
                    label: "Respuesta",
                    class: "text-center ",
                },
                {
                    key: "vermas",
                    label: "Ver más",
                    class: "text-center ",
                }
            ],
            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, {
                value: 99999,
                text: "Mostrar todo"
            }],
            iniciaCarga: false,
            filter: null,
            filterOn: [],

        };
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        CargarDatosModificables(param) {
            let me = this;
            me.modifEvaluacion.idEvaluacion = param.item.id_evaluacion;
            me.modifEvaluacion.nombre = param.item.nombre;
            me.modifEvaluacion.descripcion = param.item.descripcion;
            me.modifEvaluacion.n_preguntas = param.item.n_preguntas;
            me.$refs["modal-modificar-evaluacion"].show();
        },
        CargarDatosModificablesPregunta(param) {
            let me = this
            me.datosPregunta.idevaluacion = param.item.id_evaluacion;
            me.ListarPreguntas();
            me.$refs["modal-agregar-pregunta"].show();
        },
        ActualizarEvaluacion() {
            let me = this;
            me.cargarEvaluacion = true;
            let actualizaEvaluacion = [];
            actualizaEvaluacion.push({
                evaluacion: me.modifEvaluacion
            });
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/actualizar-evaluacion",
                    actualizaEvaluacion, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });

                    me.ListarEvaluaciones();
                    me.$refs["modal-modificar-evaluacion"].hide();

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        RegistrarEvaluacion() {
            let me = this;
            me.cargarEvaluacion = true;
            let registrarEvaluacion = [];
            registrarEvaluacion.push({
                evaluacion: me.datosEvaluacion
            });

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/registrar-evaluacion",
                    registrarEvaluacion, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });

                    me.ListarEvaluaciones();
                    me.cargarEvaluacion = false;
                    me.resetFormEvaluacion();

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'+error
                    });
                });

        },
        RegistrarPregunta() {
            let me = this;
            me.cargarPreguntas = true;
            let registrarPregunta = [];
            registrarPregunta.push({
                pregunta: me.datosPregunta
            });

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/registrar-pregunta",
                    registrarPregunta, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });
                    me.ListarPreguntas();
                    me.cargarPreguntas = false;
                    me.resetFormPregunta();

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        AnularEvaluacion(param) {
            let me = this
            let Nombre = param.item.nombre;
            me.$bvModal
                .msgBoxConfirm(
                    "Esta seguro que desea anular la evaluación " +
                    Nombre +
                    " ? ", {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "administrador/cambiar-estado-evaluacion", {
                                    idEvaluacion: param.item.id_evaluacion,
                                    estado: "1"
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarEvaluaciones();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },
        QuitarPregunta(row) {
            let me = this
            let Nombre = row.item.enunciado;
            me.$bvModal
                .msgBoxConfirm(
                    "Esta seguro que desea quitar la pregunta '" +
                    Nombre +
                    "' ? ", {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "administrador/desactivar-pregunta", {
                                    idPregunta: row.item.idPregunta,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarPreguntas();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },
        RestaurarEvaluacion(param) {
            let me = this
            let Nombre = param.item.nombre;
            me.$bvModal
                .msgBoxConfirm(
                    "Esta seguro que desea restaurar la evaluación " +
                    Nombre +
                    " ? ", {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "administrador/cambiar-estado-evaluacion", {
                                    idEvaluacion: param.item.id_evaluacion,
                                    estado: "2"
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarEvaluaciones();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },
        resetFormEvaluacion() {
            let me = this;
            me.datosEvaluacion.nombre = null
            me.datosEvaluacion.descripcion = ""
            me.datosEvaluacion.n_preguntas = null
            me.$nextTick(() => {
                me.$refs.observer.reset();
            });

        },
        resetFormPregunta() {
            let me = this;
            me.datosPregunta.enunciado = null
            me.datosPregunta.tipoPregunta = 1
            me.datosPregunta.preguntaA = null
            me.datosPregunta.preguntaB = null
            me.datosPregunta.preguntaC = null
            me.datosPregunta.respuesta = null
            me.$nextTick(() => {
                me.$refs.observer.reset();
            });
        },
        ListarEvaluaciones() {
            let me = this;
            me.cargarEvaluacion = true;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/listar-evaluaciones")
                .then(function (response) {
                    me.listaEvaluaciones = response.data;
                    me.filasTotales = me.listaEvaluaciones.length
                    me.cargarEvaluacion = false;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ListarPreguntas() {
            let me = this;
            me.cargarPreguntas = true;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/listar-preguntas", {
                        params: {
                            idEvaluacion: me.datosPregunta.idevaluacion
                        },
                    }
                ).then(function (response) {
                    me.listaPreguntas = response.data;
                    me.cargarPreguntas = false;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },

    },

    mounted() {
        if (localStorage.usuario) {
            let me = this
            var lsUsuario = JSON.parse(localStorage.usuario)
            me.ListarEvaluaciones();
        }
    }
};
</script>
