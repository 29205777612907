<!--
ver capacitaciones disponibles
seleccionar capacitacion
ver materiales
encuesta -->
<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
            <b-col xl="3" md="6">
                <stats-card title="Total" type="gradient-orange" :sub-title="datosPanel.cant_total" icon="puzzle-fill" class="mb-4">
                </stats-card>
            </b-col>
            <b-col xl="3" md="6">
                <stats-card title="Pendientes" type="gradient-blue" :sub-title="datosPanel.cant_pendientes" icon="arrow-right-square-fill" class="mb-4">
                </stats-card>
            </b-col>
            <b-col xl="3" md="6">
                <stats-card title="En curso" type="gradient-green" :sub-title="datosPanel.cant_proceso" icon="caret-right-square-fill" class="mb-4">
                </stats-card>
            </b-col>
            <b-col xl="3" md="6">
                <stats-card title="Finalizados" type="gradient-red" :sub-title="datosPanel.cant_culminado" icon="check-square-fill" class="mb-4">
                </stats-card>
            </b-col>
        </b-row>
    </base-header>
    <!--End head-->

    <!--ontainer-->
    <b-container fluid class="mt--8 mb-5">
        <div class="mt-4">
            <b-row>
                <b-col xl="12">
                    <card header-classes="bg-transparent">
                        <template v-slot:header>
                            <b-row align-v="center" slot="header">
                                <b-col>
                                    <h3 class="mb-0 "> Mis capacitaciones </h3>
                                </b-col>
                            </b-row>
                        </template>
                        <b-row>
                            <b-col lg="4">
                                <base-input v-if="!cargaCapacitacionesTrabajador">
                                    <b-input-group class="text-right" size="xl">
                                        <b-input-group-prepend is-text>
                                            <b-icon icon="search"></b-icon>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filtro" id="filterInput" placeholder="Buscar..." autocomplete="off"></b-form-input>
                                        <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                    </b-input-group>
                                </base-input>
                            </b-col>
                            <b-col lg="4">

                            </b-col>
                            <b-col lg="4">
                                <base-input v-if="!cargaCapacitacionesTrabajador">
                                    <b-form-select size="xl" v-model="porPagina" id="porPaginaSelect" :options="paginaOpciones"></b-form-select>
                                </base-input>
                            </b-col>
                            <b-col xl="12">
                                <b-list-group flush class="list my--3 ">
                                    <b-list-group-item action v-for="item of listaCapacitacionesSorter" :key="item.image" class="list-group-item ">
                                        <router-link :to="{name:'contenido capacitación',params: {id: item.id_persona_capacitacion,nombreCapacitacion: item.nombre}}" class="text-light">
                                            <b-row align-v="center">
                                                <b-col md="auto" class="movil-div">
                                                    <b-img class="movil-img img-responsive img-fluid" alt="Image" center rounded width="100px" :src="item.portada" />
                                                </b-col>
                                                <b-col class="auto">
                                                    <h4 class="mb-1 mt-2">
                                                        {{item.nombre}}
                                                    </h4>
                                                    <template v-if="item.proceso == 1">
                                                        <span class="text-primary">●
                                                            <small>Pendiente</small></span>
                                                        <base-progress animated type="blue" :value="1" >
                                                        </base-progress>
                                                    </template>
                                                    <template v-else-if="item.proceso == 2">
                                                        <span class="text-success">●
                                                            <small>En curso</small></span>
                                                        <base-progress animated type="green" :value="50" >
                                                        </base-progress>
                                                    </template>
                                                    <template v-else-if="item.proceso == 3">
                                                        <span class="text-danger">●
                                                            <small>Finalizado</small></span>
                                                        <base-progress animated type="red" :value="100" >
                                                        </base-progress>
                                                    </template>
                                                </b-col>
                                                <!--     <b-col md="auto text-left" class="mb-1 mt-1">
                                                <router-link :to="{name:'contenido capacitación',params: {id: item.id_persona_capacitacion,nombreCapacitacion: item.nombre}}" class="text-light">
                                                    <b-button type="button" size="sm" variant="default">Seleccionar
                                                        <b-icon icon=" arrow-right" aria-label="Help">
                                                        </b-icon>
                                                    </b-button>
                                                </router-link>
                                            </b-col>-->
                                            </b-row>
                                            <!--    <b-badge class="mt-2 defaultx">{{item.nombre_tipo}}</b-badge>-->
                                        </router-link>

                                    </b-list-group-item>
                                </b-list-group>
                            </b-col>
                        </b-row>
                        <template v-slot:footer>
                            <b-row v-if="!cargaCapacitacionesTrabajador">
                                <b-col lg="12">
                                    <b-pagination style="justify-content:flex-end;" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                                </b-col>
                            </b-row>
                        </template>
                    </card>
                </b-col>
            </b-row>
        </div>
    </b-container>
    <!--End container-->
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    name: "capacitaciones",
    components: {},
    data() {
        return {
            cargaCapacitacionesTrabajador: true,
            listaCapacitacionesTrabajador: [],
            datosPanel: {
                cant_total: "",
                cant_pendientes: "",
                cant_proceso: "",
                cant_culminado: "",
            },
            datosPersona: {
                idPersona: "",
            },

            paginaActual: 1,
            porPagina: 5,
            paginaOpciones: [5, 10, 20, {
                value: 99999,
                text: "Mostrar todo"
            }],
            filtro: "",
        };
    },
    filters: {
        truncate: function (text, length, suffix) {
            return text.substring(0, length) + suffix;
        },
    },
    methods: {
        ContarDatosPanel() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "trabajador/contar-datos-panel", {
                        params: {
                            idPersona: me.datosPersona.idPersona,
                        }
                    }
                )
                .then(function (response) {
                    if (response) {
                        me.datosPanel.cant_total = String(response.data[0].cant_total)
                        me.datosPanel.cant_pendientes = String(response.data[0].cant_pendientes)
                        me.datosPanel.cant_proceso = String(response.data[0].cant_proceso)
                        me.datosPanel.cant_culminado = String(response.data[0].cant_culminado)
                    }
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ListarCapacitacionesTrabajador() {
            let me = this;
            me.cargaCapacitacionesTrabajador = true
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "trabajador/listar-capacitaciones-trabajador", {
                        params: {
                            idPersona: me.datosPersona.idPersona,
                        }
                    }
                )
                .then(function (response) {
                    if (response) {
                        me.listaCapacitacionesTrabajador = response.data
                        me.cargaCapacitacionesTrabajador = false
                    }
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },

    },
    computed: {
        listaFiltrados() {
            return this.listaCapacitacionesTrabajador.filter(itemx => {
                return itemx.nombre.toLowerCase().indexOf(this.filtro.toLowerCase().trim()) > -1
            })
        },
        listaCapacitacionesSorter() {
            const items = this.listaFiltrados
            return items.slice(
                (this.paginaActual - 1) * this.porPagina,
                this.paginaActual * this.porPagina
            )
        },
        filasTotales() {
            return this.listaFiltrados.length
        }
    },
    mounted() {
        if (localStorage.usuario) {
            let me = this
            var lsUsuario = JSON.parse(localStorage.usuario)
            me.datosPersona.idPersona = lsUsuario.idPersona
            me.ListarCapacitacionesTrabajador();
            me.ContarDatosPanel();
        }
    }
};
</script>
