<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
            <b-col xl="12 mb-5">
                <card header-classes="bg-transparent">
                    <template v-slot:header>
                        <b-row align-v="center" slot="header">
                            <b-col>
                                <h3 class="mb-0">Seleccionar Cliente </h3>
                            </b-col>
                        </b-row>
                    </template>
                    <b-row class="justify-content-center">
                        <b-col lg="8" sm="12">
                            <b-dropdown size="sm" style="display: flex;" variant="warning" block menu-class="w-100" class="text-center">
                                <!--titulo-->
                                <template v-slot:button-content v-if="datosCliente.razonSocial.length === 0">
                                    <b-icon icon="border-width"></b-icon> SELECCIONAR CLIENTE
                                </template>
                                <template v-slot:button-content v-else>
                                    {{datosCliente.razonSocial}}
                                </template>
                                <!--end- titulo-->
                                <b-row>
                                    <b-col lg="12">
                                        <b-dropdown-form @submit.stop.prevent="() => {}">
                                            <b-form-group style="padding-left:10px;padding-right:10px" label-for="BuscarCliente" label="Buscar" label-cols-md="auto" class="mb-0 labelx" label-size="sm" :description="MensajeBusqueda">
                                                <b-form-input v-model="buscarCliente" id="BuscarCliente" type="search" size="sm" autocomplete="off"></b-form-input>
                                            </b-form-group>
                                        </b-dropdown-form>
                                    </b-col>
                                </b-row>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item-button v-for="option in OpcionesDisponibles" :key="option.idCliente" @click="onOptionClick(option)">
                                    {{ option.razonSocial }}
                                </b-dropdown-item-button>
                                <!--     <b-dropdown-item-button v-for=" option in OpcionesDisponibles" :key="option.idCliente" @click="onOptionClick(option)">{{ option.razonSocial }}</b-dropdown-item-button>-->

                            </b-dropdown>
                        </b-col>
                    </b-row>
                </card>
            </b-col>
        </b-row>
    </base-header>
    <!--End head-->

    <!--ontainer-->
    <b-container fluid class="mt--8 mb-5">
        <div class="mt-0">
            <b-row>
                <b-col xl="12">
                    <b-overlay :show="cargaCapacitacionesCliente" rounded="lg" opacity="0.5">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                                <b-spinner type="grow" variant="dark"></b-spinner>
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                            </div>
                        </template>
                        <card header-classes="bg-transparent">
                            <template v-slot:header>
                                <b-row align-v="center" slot="header">
                                    <b-col>
                                        <h3 class="mb-0">Capacitaciones </h3>
                                    </b-col>
                                    <b-col class="text-right">
                                        <span class="btn btn-sm btn-primary" v-b-modal.modal-asignar-capacitacion>
                                            <b-icon icon="person-plus-fill" aria-hidden="true"></b-icon> Asignar capacitación
                                        </span>
                                    </b-col>
                                </b-row>
                            </template>
                            <b-row>
                                <b-col lg="4">
                                    <base-input v-if="!cargaCapacitacionesCliente">
                                        <b-input-group class="text-right" size="xl">
                                            <b-input-group-prepend is-text>
                                                <b-icon icon="search"></b-icon>
                                            </b-input-group-prepend>
                                            <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                            <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                        </b-input-group>
                                    </base-input>
                                </b-col>
                                <b-col lg="4">

                                </b-col>
                                <b-col lg="4">
                                    <base-input v-if="!cargaCapacitacionesCliente">
                                        <b-form-select size="xl" v-model="porPagina" id="porPaginaSelect" :options="paginaOpciones"></b-form-select>
                                    </base-input>
                                </b-col>
                                <b-col lg="12">
                                    <b-table sort-icon-left show-empty small responsive outlined :items="listaCapacitacionesCliente" :fields="camposCapacitaciones" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay capacitaciones en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                        <template v-slot:cell(estado)="data">
                                            <b class="text-danger" v-if="data.value=='1'">INACTVO</b>
                                            <b class="text-success" v-else>ACTIVO</b>
                                        </template>
                                        <template v-slot:cell(actions)="param">
                                            <template v-if="param.item.estado == 2">
                                                <base-button icon size="sm" type="danger" @click="EliminarCapacitacionCliente(param)">
                                                    <span class="btn-inner--icon">
                                                        <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                                                    </span>
                                                    <span class="btn-inner--text">Eliminar</span>
                                                </base-button>
                                            </template>
                                            <template v-if="param.item.estado == 1">
                                                <base-button icon size="sm" type="success" @click="RestaurarCapacitacionCliente(param)">
                                                    <span class="btn-inner--icon">
                                                        <b-icon icon="arrow-counterclockwise" aria-label="Help"></b-icon>
                                                    </span>
                                                    <span class="btn-inner--text">Restaurar</span>
                                                </base-button>
                                            </template>
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                            <template v-slot:footer>
                                <b-row v-if="!cargaCapacitacionesCliente">
                                    <b-col lg="12">
                                        <b-pagination style="justify-content:flex-end;" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                                    </b-col>
                                </b-row>
                            </template>
                        </card>
                    </b-overlay>
                </b-col>
            </b-row>
        </div>
    </b-container>
    <!--End container-->
    <b-modal id="modal-asignar-capacitacion" ref="modal-asignar-capacitacion" title="Asingar nueva capacitación" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetModalAsignar">
        <b-row>
            <b-col xl="12">
                <b-table :items="listaCapacitacionesModal" sticky-header="300px" class="mt-2" small outlined selectable select-mode="multi" @row-selected="onRowSelected" :fields="camposCapacitacionesSmall" responsive show-empty empty-text="No se encontraron capacitaciones">
                    <template #cell(index)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only">Seleccionado</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                            <span class="sr-only">No seleccionado</span>
                        </template>
                    </template>
                    <template v-slot:cell(estado)="data">
                        <b class="text-danger" v-if="data.value=='1'">INACTVO</b>
                        <b class="text-success" v-else>ACTIVO</b>
                    </template>
                </b-table>
            </b-col>
            <b-col lg="12 text-right mt-3 mb-3">
                <b-button size="sm" variant="success" @click="AsignarCapacitacion()">
                    Añadir Capacitaciones
                </b-button>
                <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-asignar-capacitacion')">Cerrar</b-button>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    name: "asignacionCapacitaciones",
    components: {},
    data() {
        return {
            cargaCapacitacionesCliente: true,
            capacitacionesSeleccionadas: [],
            listaClientes: [],
            listaClientesFiltrada: [],
            listaCapacitacionesModal: [],
            listaCapacitacionesCliente: [],

            buscarCliente: "",
            datosCliente: {
                idCliente: "",
                razonSocial: "",
            },

            camposCapacitacionesSmall: [{
                    key: "index",
                    label: "",
                    class: "text-center pointerx tdx ",
                    style: "",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center pointerx",
                },
                {
                    key: "nombre_tipo",
                    label: "Tipo",
                    class: "text-center pointerx",
                },
                {
                    key: "estado",
                    label: "Estado",
                    class: "text-center ",
                }
            ],
            camposCapacitaciones: [{
                    key: "index",
                    label: "N°",
                    class: "text-center ",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center ",
                },
                {
                    key: "nombre_tipo",
                    label: "Tipo",
                    class: "text-center ",
                },

                {
                    key: "actions",
                    label: "opciones",
                    class: "text-center ",
                },
            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, {
                value: 99999,
                text: "Mostrar todo"
            }],

            filter: null,
            filterOn: [],
        };
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        ListarClientes() {
            let me = this
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/listar-clientes-afiliados", {

                })
                .then(function (response) {
                    me.listaClientes = response.data;

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ListarCapacitacionesCliente() {
            let me = this;
            me.cargaCapacitacionesCliente = true
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "administrador/listar-capacitaciones-cliente", {
                        params: {
                            idCliente: me.datosCliente.idCliente,
                        }
                    }
                )
                .then(function (response) {
                    if (response) {
                        me.listaCapacitacionesCliente = response.data
                        me.filasTotales = me.listaCapacitacionesCliente.length
                        me.cargaCapacitacionesCliente = false
                    }
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ListarCapacitacionesModal() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/listar-capacitaciones-asignacion", {
                    params: {
                        idCliente: me.datosCliente.idCliente
                    }
                })
                .then(function (response) {
                    if (response) {
                        me.listaCapacitacionesModal = response.data
                    }
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        onOptionClick(option) {
            let me = this;
            me.datosCliente.razonSocial = option.razonSocial;
            for (var i = 0; i < me.listaClientes.length; i++) {
                if (me.listaClientes[i].razonSocial === option.razonSocial) {
                    me.datosCliente.idCliente = me.listaClientes[i].idCliente;
                    me.datosCliente.razonSocial = me.listaClientes[i].razonSocial;
                }
            }
            me.ListarCapacitacionesCliente();
            me.ListarCapacitacionesModal();

        },
        onRowSelected(items) {
            let me = this;
            items.forEach((a) => {
                a.idCliente = me.datosCliente.idCliente
            })
            me.capacitacionesSeleccionadas = items
        },

        AsignarCapacitacion() {
            let me = this
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/asignar-capacitacion",
                    me.capacitacionesSeleccionadas, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });

                    me.$refs["modal-asignar-capacitacion"].hide();
                    me.ListarCapacitacionesCliente();
                    me.ListarCapacitacionesModal();
                    me.resetModalAsignar()

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        resetModalAsignar() {
            let me = this
            me.capacitacionesSeleccionadas = []
        },
        EliminarCapacitacionCliente(param) {
            let me = this;
            let NombreCapacitacion = param.item.nombre;
            me.$bvModal
                .msgBoxConfirm(
                    "Esta seguro que desea quitar la capacitación " +
                    NombreCapacitacion +
                    " ? ", {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "administrador/eliminar-capacitacion-cliente", {
                                    id_cliente_capacitacion: param.item.id_cliente_capacitacion,
                                    estado: "1"
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarCapacitacionesCliente();
                                    me.ListarCapacitacionesModal();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },

        RestaurarCapacitacionCliente(param) {
            let me = this;
            let NombreCapacitacion = param.item.nombre;
            me.$bvModal
                .msgBoxConfirm(
                    "Esta seguro que desea restaurar la capacitación " +
                    NombreCapacitacion +
                    " ? ", {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "administrador/eliminar-capacitacion-cliente", {
                                    id_cliente_capacitacion: param.item.id_cliente_capacitacion,
                                    estado: "2"
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarCapacitacionesCliente();
                                    me.ListarCapacitacionesModal();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        }
    },
    computed: {
        criteria() {
            let me = this
            return me.buscarCliente.trim().toLowerCase()
        },
        OpcionesDisponibles() {
            let me = this
            const criteria = me.criteria
            const optionsx = me.listaClientes.filter(opt => me.listaClientesFiltrada.indexOf(opt) === -1)
            if (criteria) {
                return optionsx.filter(opt => opt.razonSocial.toLowerCase().indexOf(criteria) > -1);
            }
            return optionsx
        },
        MensajeBusqueda() {
            let me = this
            if (me.criteria && me.OpcionesDisponibles.length === 0) {
                return 'Cliente no encontrado, pruebe con otro criterio de busqueda'
            }
            return ''
        }
    },
    mounted() {
        if (localStorage.usuario) {
            let me = this
            var lsUsuario = JSON.parse(localStorage.usuario)
            me.ListarClientes();
        }
    }
};
</script>
