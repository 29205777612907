<script>
  import { Line } from 'vue-chartjs'

  export default {
    extends: Line,
     props: ["data", "options"],
    data () {
      return {
      /*  chartDataBar: {
          labels: ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
          datasets: [
            {
              label: 'Evaluaciones',
              data: [600,1150,342,2050,2522,3241,1259,557,1545,3841,1453,841],
              fill: false,
              borderColor: '#2554FF',
              backgroundColor: '#2554FF',
              borderWidth: 1
            }
          ]
        },
        optionsBar: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [ {
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false
        }*/
      }
    },
    mounted () {
      this.renderChart(this.data, {})
    }
  }
</script>