<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
        </b-row>
    </base-header>
    <!--End head-->

    <!--ontainer-->
    <b-container fluid class="mt--8 mb-5">
        <b-row>
            <b-col xl="12">
                <card header-classes="bg-transparent">
                    <template v-slot:header>
                        <b-row align-v="center" slot="header">
                            <b-col>
                                <h3 class="mb-0">Plantilla </h3>
                            </b-col>
                            <b-col class="text-right">
                                <span class="btn btn-sm btn-primary" v-b-modal.modal-registro-cliente>
                                    <b-icon icon="person-plus-fill" aria-hidden="true"></b-icon> Agregar Cliente
                                </span>
                            </b-col>
                        </b-row>
                    </template>
                </card>
            </b-col>

            <b-col xl="12 mt-4">
                <card header-classes="bg-transparent ">
                    <template v-slot:header>
                        <b-row align-v="center" slot="header">
                            <b-col>
                                <h3 class="mb-0">Plantilla </h3>
                            </b-col>
                            <b-col class="text-right">
                                <span class="btn btn-sm btn-primary" v-b-modal.modal-registro-cliente>
                                    <b-icon icon="person-plus-fill" aria-hidden="true"></b-icon> Agregar Cliente
                                </span>
                            </b-col>
                        </b-row>
                    </template>
                      <base-switch class="mr-1" v-model="sw"></base-switch>
                        {{sw}}
                
                    <b-row>
                      
                        <b-col lg="4">
                            <base-input v-if="isBusy==false">
                                <b-input-group class="text-right" size="xl">
                                    <b-input-group-prepend is-text>
                                        <b-icon icon="search"></b-icon>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input>
                                <b-button class="btn btn-default" @click="toggleBusy">Toggle Busy State</b-button>
                            </base-input>

                        </b-col>
                        <b-col lg="4">
                            <base-input v-if="isBusy==false">
                                <b-form-select size="xl" v-model="porPagina" id="porPaginaSelect" :options="paginaOpciones"></b-form-select>
                            </base-input>
                        </b-col>

                        <b-col lg="12">
                            <b-table :busy="isBusy" sort-icon-left show-empty small responsive outlined :items="items" :fields="fields"  :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template #cell(name)="row">
                                    {{ row.value.first }} {{ row.value.last }}
                                </template>
                                <template v-slot:table-busy>
                                    <div class="text-center " style="color:#6b6b6b;">
                                        <b-spinner small class="align-middle"></b-spinner>
                                        <strong> Cargando...</strong>
                                    </div>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row v-if="isBusy==false">
                        <b-col lg="12 mt-3">
                            <b-pagination style="justify-content:flex-end;" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                        </b-col>
                    </b-row>
                </card>
            </b-col>
        </b-row>
    </b-container>
    <!--End container-->
</div>
</template>

<script>
//import axios from "axios";
//import CONSTANTES from "@/Constantes.js";

export default {
    name: "plantilla",
    components: {},
    data() {
        return {
            sw: "",
            isBusy: false,
            items: [{
                    isActive: true,
                    age: 40,
                    name: {
                        first: 'Dickerson',
                        last: 'Macdonald'
                    }
                },
                {
                    isActive: false,
                    age: 21,
                    name: {
                        first: 'Larsen',
                        last: 'Shaw'
                    }
                },
                {
                    isActive: false,
                    age: 9,
                    name: {
                        first: 'Mini',
                        last: 'Navarro'
                    },
                    _rowVariant: 'success'
                },
                {
                    isActive: false,
                    age: 89,
                    name: {
                        first: 'Geneva',
                        last: 'Wilson'
                    }
                },
                {
                    isActive: true,
                    age: 38,
                    name: {
                        first: 'Jami',
                        last: 'Carney'
                    }
                },
                {
                    isActive: false,
                    age: 27,
                    name: {
                        first: 'Essie',
                        last: 'Dunlap'
                    }
                },
                {
                    isActive: true,
                    age: 40,
                    name: {
                        first: 'Thor',
                        last: 'Macdonald'
                    }
                },
                {
                    isActive: true,
                    age: 87,
                    name: {
                        first: 'Larsen',
                        last: 'Shaw'
                    },
                    _cellVariants: {
                        age: 'danger',
                        isActive: 'warning'
                    }
                },
                {
                    isActive: false,
                    age: 26,
                    name: {
                        first: 'Mitzi',
                        last: 'Navarro'
                    }
                },
                {
                    isActive: false,
                    age: 22,
                    name: {
                        first: 'Genevieve',
                        last: 'Wilson'
                    }
                },
                {
                    isActive: true,
                    age: 38,
                    name: {
                        first: 'John',
                        last: 'Carney'
                    }
                },
                {
                    isActive: false,
                    age: 29,
                    name: {
                        first: 'Dick',
                        last: 'Dunlap'
                    }
                }
            ],
            fields: [{
                    key: 'name',
                    label: 'Person full name',
                    sortable: true,
                    icon: "fa fa-fw fa-sort",
                    sortDirection: 'desc'
                },
                {
                    key: 'age',
                    label: 'Person age',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'isActive',
                    label: 'Is Active',
                    formatter: (value, key, item) => {
                        return value ? 'Yes' : 'No'
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true
                },
                {
                    key: 'actions',
                    label: 'Actions'
                }
            ],
           
            filasTotales: 1,
            paginaActual: 1,
            porPagina: 5,
            paginaOpciones: [5, 10, 15, {
                value: 100,
                text: "Mostrar todo"
            }],

            filter: null,
            filterOn: [],

        };
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        toggleBusy() {
            let me = this
            me.isBusy = !me.isBusy
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        if (localStorage.usuario) {
            var lsUsuario = JSON.parse(localStorage.usuario)
            this.filasTotales = this.items.length
        }
    }
};
</script>
