<script>
  import { Pie } from 'vue-chartjs'

  export default {
    extends: Pie,
      props: ["data", "options"],
    data () {
      return {
   /*     chartData: {
      
          datasets: [{
              borderWidth: 1,
              borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
               'rgba(255,99,132,1)'],
              backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)'  ,
              'rgba(255, 99, 132, 0.2)' ],
              data: [2,	4,	6]
            }],
         labels: [ " Pendiente ", " En proceso ", " Finalizado "],

        },
        options: {
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false
        }*/
      }
    },
    mounted () {
      this.renderChart(this.data,{})
    }
  }
</script>