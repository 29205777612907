<template>
<div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">

        <div class="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
            </svg>
        </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
        <!-- Table -->
        <b-row class="justify-content-center">
            <b-col lg="5" md="7">
                <b-card no-body class="bg-secondary border-0">
                    <b-card-header class="bg-transparent pb-4">
                        <img src="/img/brand/logo.png" class="img-fluid">
                    </b-card-header>
                    <b-overlay :show="respuestaRecuperar" rounded="lg" opacity="0.5">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                                <b-spinner type="grow" variant="dark"></b-spinner>
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                            </div>
                        </template>
                        <b-card-body class="px-lg-5 py-lg-5">
                            <div class="text-center text-muted mb-4">
                                <small>Ingrese sus credenciales</small>
                            </div>
                            <b-alert :show="dismissCountDown" dismissible :variant="variant" @dismissed="dismissCountDown = 0" @dismiss-count-down="countDownChanged">{{ message }}</b-alert>
                            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                                    <base-input alternative class="mb-3" prepend-icon="person-badge-fill" placeholder="Ingrese su documento de identidad" name="de documento de identidad" :rules="{required: true}" v-model="documento">
                                    </base-input>
                                    <base-input alternative class="mb-3" prepend-icon="envelope-fill" placeholder="Ingrese su correo" name="de correo" :rules="{required: true, email: true}" v-model="correo">
                                    </base-input>
                                    <div class="text-center">
                                        <b-button type="submit" variant="primary" class="mt-3">Recuperar contraseña</b-button>
                                    </div>
                                </b-form>
                            </validation-observer>
                        </b-card-body>
                    </b-overlay>
                </b-card>
                <b-row class="mt-3">
                    <b-col cols="8">
                        <router-link :to="{name:'login'}" class="text-light"><small>Regresar al login</small></router-link>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    name: 'recuperar',
    data() {
        return {
            documento: null,
            correo: null,
            variant: "",
            message: "",
            dismissSecs: 8,
            dismissCountDown: 0,
            respuestaRecuperar: false
        }
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
        onSubmit() {
            let me = this;
            me.respuestaRecuperar = true;
            axios
                .post(CONSTANTES.URL_RUTA_SERVICIOS + "auth/recuperar", {
                    correo: me.correo,
                    documento: me.documento
                })
                .then(function (response) {
                    me.variant = response.data.typemessage
                    me.message = response.data.message;
                    me.dismissCountDown = me.dismissSecs;
                    me.respuestaRecuperar = false;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });

        }
    },
    mounted() {
        localStorage.token = "";
        localStorage.usuario = "";
    }
};
</script>
