<template>
<div>
    <div class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center profile-header" style="min-height: 500px; background-image: url(https://i1.wp.com/tekzup.com/wp-content/uploads/2016/10/website_.jpg); background-size: cover; background-position: center top;">
        <b-container fluid>
            <!-- Mask -->
            <span class="mask bg-gradient-warning opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="d-flex align-items-center">
                <b-row>
                    <b-col lg="12" md="12">
                        <h1 class="display-3 text-white">HOLA, {{datosPersona.Nombres}}</h1>
                    </b-col>
                    <b-col lg="5" md="10">
                        <p class="text-white mt-0 mb-5">Esta es tu página de perfil. Puedes actualizar tu información si lo requieres.</p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </div>

    <b-container fluid class="mt--9 mb-5">
        <b-row>
            <b-col xl="7" sm="12 mb-7">
                <b-overlay :show="cargarDatos" rounded="lg" opacity="0.5">
                    <template v-slot:overlay>
                        <div class="d-flex align-items-center">
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                            <b-spinner type="grow" variant="dark"></b-spinner>
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                        </div>
                    </template>
                    <card>
                        <b-row align-v="center" slot="header">
                            <b-col cols="7">
                                <h3 class="mb-0"> Información </h3>
                            </b-col>
                        </b-row>
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.prevent="handleSubmit(ActualizarInformacion)">
                                <!--       <h6 class="heading-small text-muted mb-4">Información</h6>-->
                                <div class="pl-lg-2 pr-lg-2">
                                    <b-row>
                                        <b-col lg="6">
                                            <base-input label="Nombres" name="de nombres " :rules="{ required: true }" placeholder="Ingrese nombres" input-classes="form-control-sm" v-model="modifDatosPersona.Nombres"> </base-input>
                                        </b-col>
                                        <b-col lg="6">
                                            <base-input label="Apellido Paterno" name="de apellido paterno " :rules="{ required: true }" placeholder="Ingrese apellido Paterno" input-classes="form-control-sm" v-model="modifDatosPersona.ApellidoPaterno"> </base-input>
                                        </b-col>
                                        <b-col lg="6">
                                            <base-input label="Apellido Materno" name="de apellido materno " :rules="{ required: true }" placeholder="Ingrese apellido Materno" input-classes="form-control-sm" v-model="modifDatosPersona.ApellidoMaterno"> </base-input>
                                        </b-col>
                                        <b-col lg="6">
                                            <base-input label="Fecha de Nacimiento" name="de fecha de nacimiento " type="date" :rules="{ required: true }" placeholder="Ingrese fecha de nacimiento" input-classes="form-control-sm" v-model="modifDatosPersona.FechaNacimiento"> </base-input>
                                        </b-col>
                                        <b-col lg="6">
                                            <base-input label="Estado Civil" name="de Estado Civil" :rules="{ required: true }">
                                                <b-form-select aria-describedby="input-1-live-feedback" id="estadocivil" :options="comboEstadoCivil" v-model="modifDatosPersona.idEstadoCivil" size="sm" value-field="idEstadoCivil" text-field="Descripcion">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>- Estado Civil -</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                            </base-input>
                                        </b-col>
                                        <b-col lg="6">
                                            <base-input label="Sexo" name="de sexo ">
                                                <b-form-select aria-describedby="input-1-live-feedback" id="sexo" :options="comboSexo" v-model="modifDatosPersona.Sexo" size="sm">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>- Sexo -</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                            </base-input>
                                        </b-col>
                                    </b-row>
                                    <hr class="my-4">
                                    <b-row>
                                        <b-col lg="12" class="text-right">
                                            <b-button variant="success" size="sm" type="submit"><span class="btn-inner--icon">
                                                    <b-icon icon="check2-circle" aria-label="Help"></b-icon>
                                                </span> Actualizar Información</b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-form>
                        </validation-observer>
                    </card>
                </b-overlay>
            </b-col>
            <b-col xl="5" sm="12">
                <b-card no-body class="card-profile" alt="Image placeholder" img-top>
                    <b-row class="justify-content-center">
                        <b-col lg="3" class="order-lg-2">
                            <div class="card-profile-image">
                                <a href="#">
                                    <b-img style="z-index: 11;" src="img/icons/icon_persona.png" rounded="circle" />
                                </a>
                            </div>
                        </b-col>
                    </b-row>
                    <b-overlay :show="cargarDatos" rounded="lg" opacity="0.5">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                                <b-spinner type="grow" variant="dark"></b-spinner>
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                            </div>
                        </template>
                        <b-card-body class="pt-9">
                            <div class="text-center">
                                <h5 class="h4">
                                    {{datosPersona.Nombres}}<span class="font-weight-light">, {{datosPersona.edad}}</span>
                                </h5>
                                <div class="h5 font-weight-300">
                                    <i class="ni location_pin mr-2"></i>{{datosPersona.correo}}
                                </div>
                                <div class="h5 mt-4">
                                    <i class="ni business_briefcase-24 mr-2"></i>{{datosPersona.descTipoDoc}} - {{datosPersona.numeroDocumento}}
                                </div>
                                <div>
                                    <i class="ni education_hat mr-2"></i>{{datosCliente.razonSocial}}
                                </div>
                                <hr class="my-4">
                                <b-row>
                                    <b-col cols="12" class="text-center">
                                        <base-button icon size="sm" type="default" v-b-modal.modal-cambiar-contrasena>
                                            <span class="btn-inner--icon">
                                                <b-icon icon="lock-fill" aria-label="Help"></b-icon>
                                            </span>
                                            <span class="btn-inner--text">Cambiar contraseña </span>
                                        </base-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card-body>
                    </b-overlay>
                </b-card>
            </b-col>
        </b-row>
    </b-container>

    <b-modal id="modal-cambiar-contrasena" centered ref="modal-cambiar-contrasena" title="Actualizar Contraseña" size="sm" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetModalContrasena">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(ActualizarContrasena)">
                <div class="pl-lg-2 pr-lg-2">
                    <b-row>
                        <b-col xl="12" lg="12" sm="12">
                            <base-input type="password" prepend-icon="lock-fill" alternative name="de contraseña actual" :rules="{ required: true,min:8,max:14 }" placeholder="Ingrese contraseña actual" v-model="datosContrasena.contrasenaActual"> </base-input>
                        </b-col>
                        <b-col xl="12" lg="12" sm="12">
                            <base-input type="password" prepend-icon="unlock-fill" alternative name="de nueva contraseña" :rules="{ required: true,min:8,max:14 }" placeholder="Ingrese nueva contraseña" v-model="datosContrasena.contrasenaNueva"> </base-input>
                        </b-col>
                        <b-col xl="12" lg="12" sm="12">
                            <base-input type="password" prepend-icon="unlock-fill" alternative name="de repetir nueva contraseña" :rules="{ required: true,min:8,max:14}" placeholder="Ingrese otra vez la nueva contraseña" v-model="datosContrasena.contrasenaNuevaRepetida"> </base-input>
                        </b-col>
                        <b-col lg="12 text-right">
                            <b-button size="sm" variant="success" class="mb-2" type="submit">
                                Actualizar contraseña
                            </b-button>
                            <b-button class="mb-2" size="sm" variant="danger" @click="$bvModal.hide('modal-cambiar-contrasena')">Cerrar</b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {},
    data() {
        return {
            comboEstadoCivil: [],
            comboTipoDocumento: [],
            comboSexo: [{
                    value: "M",
                    text: "Masculino"
                },
                {
                    value: "F",
                    text: "Femenino"
                }
            ],
            cargarDatos: true,
            datosPersona: {
                ApellidoMaterno: "",
                ApellidoPaterno: "",
                FechaNacimiento: "",
                Nombres: "",
                Sexo: "",
                edad: "",
                correo: "",
                descTipoDoc: "",
                idEstadoCivil: "",
                idPersona: "",
                idTipoDocumento: "",
                numeroDocumento: "",
                shortName: "",
            },
            modifDatosPersona: {
                ApellidoMaterno: "",
                ApellidoPaterno: "",
                FechaNacimiento: "",
                usuario: "",
                Nombres: "",
                Sexo: "",
                idEstadoCivil: "",
                idPersona: "",
            },
            datosContrasena: {
                usuario: "",
                contrasenaActual: "",
                contrasenaNueva: "",
                contrasenaNuevaRepetida: ""
            },
            datosCliente: {
                razonSocial: "",
            }
        };
    },
    methods: {
        resetModalContrasena() {
            let me = this
            me.datosContrasena.contrasenaActual = ""
            me.datosContrasena.contrasenaNueva = ""
            me.datosContrasena.contrasenaNuevaRepetida = ""
        },
        obtenerEstadoCivil() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-estado-civil")
                .then(function (response) {
                    me.comboEstadoCivil = response.data;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        obtenerTipoDocumento() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-tipo-documento")
                .then(function (response) {
                    me.comboTipoDocumento = response.data;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        obtenerDatosPersona() {
            let me = this;
            me.cargarDatos = true
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-datos-persona", {
                    params: {
                        idPersona: me.datosPersona.idPersona
                    }
                })
                .then(function (response) {
                    me.datosPersona = response.data[0];
                    me.modifDatosPersona.Nombres = response.data[0].Nombres;
                    me.modifDatosPersona.ApellidoPaterno = response.data[0].ApellidoPaterno;
                    me.modifDatosPersona.ApellidoMaterno = response.data[0].ApellidoMaterno;
                    me.modifDatosPersona.FechaNacimiento = response.data[0].FechaNacimiento;
                    me.modifDatosPersona.Sexo = response.data[0].Sexo;
                    me.modifDatosPersona.idPersona = response.data[0].idPersona;
                    me.modifDatosPersona.idEstadoCivil = response.data[0].idEstadoCivil;
                    me.modifDatosPersona.usuario = response.data[0].correo;
                    me.cargarDatos = false
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ActualizarInformacion() {
            let me = this;
            me.cargarDatos = true
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "generales/actualizar-perfil-persona",
                    me.modifDatosPersona, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });
                    
                    me.obtenerDatosPersona()
                    me.cargarDatos = false

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                })
        },
        ActualizarContrasena() {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "generales/cambiar-contrasena",
                    me.datosContrasena, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 0 ? 'danger' : 'success'
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });
                    me.resetModalContrasena();
                    me.$refs["modal-cambiar-contrasena"].hide();
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        }
    },
    watch: {

    },
    mounted() {
        if (localStorage.usuario) {
            let me = this
            var lsUsuario = JSON.parse(localStorage.usuario)
            me.datosPersona.idPersona = lsUsuario.idPersona
            me.datosCliente.razonSocial = lsUsuario.razonSocial
            me.datosContrasena.usuario = lsUsuario.username
            me.obtenerEstadoCivil();
            me.obtenerTipoDocumento();
            me.obtenerDatosPersona();
        }
    }
};
</script>
