<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
            <b-col xl="12" class="mb-5">
                <card header-classes="bg-transparent">
                    <template v-slot:header>
                        <b-row align-v="center" slot="header">
                            <b-col>
                                <h3 class="mb-0">Seleccionar Cliente </h3>
                            </b-col>
                        </b-row>
                    </template>
                    <b-row class="justify-content-center">
                        <b-col lg="8" sm="12">
                            <b-dropdown size="sm" style="display: flex;" variant="warning" block menu-class="w-100" class="text-center">
                                <!--titulo-->
                                <template v-slot:button-content v-if="datosCliente.razonSocial.length === 0">
                                    <b-icon icon="border-width"></b-icon> SELECCIONAR CLIENTE
                                </template>
                                <template v-slot:button-content v-else>
                                    {{datosCliente.razonSocial}}
                                </template>
                                <!--end- titulo-->
                                <b-row>
                                    <b-col lg="12 ">
                                        <b-dropdown-form @submit.stop.prevent="() => {}">
                                            <b-form-group label-for="BuscarCliente" label="Buscar" style="padding-left:10px;padding-right:10px" label-cols-md="auto" class="mb-0 labelx " label-size="sm" :description="MensajeBusqueda">
                                                <b-form-input v-model="buscarCliente" id="BuscarCliente" type="search" size="sm" autocomplete="off"></b-form-input>
                                            </b-form-group>
                                        </b-dropdown-form>
                                    </b-col>
                                </b-row>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item-button v-for="option in OpcionesDisponibles" :key="option.idCliente" @click="onOptionClick(option)">
                                    {{ option.razonSocial }}
                                </b-dropdown-item-button>
                                <!--     <b-dropdown-item-button v-for=" option in OpcionesDisponibles" :key="option.idCliente" @click="onOptionClick(option)">{{ option.razonSocial }}</b-dropdown-item-button>-->

                            </b-dropdown>
                        </b-col>
                    </b-row>
                </card>
            </b-col>
        </b-row>
    </base-header>
    <!--End head-->

    <!--ontainer-->
    <b-container fluid class="mt--8 mb-5">
        <b-row>
            <b-col xl="12">
                <b-overlay :show="cargaResponsables" rounded="lg" opacity="0.5">
                    <template v-slot:overlay>
                        <div class="d-flex align-items-center">
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                            <b-spinner type="grow" variant="dark"></b-spinner>
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                        </div>
                    </template>
                    <card header-bg-variant="info" header-classes="bg-transparent">
                        <template v-slot:header>
                            <b-row align-v="center">
                                <b-col>
                                    <h3 class="mb-0">Responsables</h3>
                                </b-col>
                                <b-col class="text-right">
                                    <span class="btn btn-sm btn-primary" v-b-modal.modal-agregar-responsable>
                                        <b-icon icon="person-plus-fill" aria-hidden="true"></b-icon> Agregar Responsable
                                    </span>
                                </b-col>
                            </b-row>
                        </template>
                        <b-row>
                            <b-col lg="4">
                                <base-input v-if="!cargaResponsables">
                                    <b-input-group class="text-right" size="xl">
                                        <b-input-group-prepend is-text>
                                            <b-icon icon="search"></b-icon>
                                        </b-input-group-prepend>
                                        <b-form-input autocomplete="off" type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                        <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                    </b-input-group>
                                </base-input>
                            </b-col>
                            <b-col lg="4">
                            </b-col>
                            <b-col lg="4">
                                <base-input v-if="!cargaResponsables">
                                    <b-form-select size="xl" v-model="porPagina" id="porPaginaSelect" :options="paginaOpciones"></b-form-select>
                                </base-input>
                            </b-col>
                            <b-col lg="12">
                                <b-table sort-icon-left show-empty small responsive outlined :items="listaResponsables" :fields="camposAdministracion" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay reponsables en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(actions)="param">
                                        <template v-if="param.item.estado == 2">
                                            <base-button icon size="sm" type="danger" @click="AnularResponsable(param)">
                                                <span class="btn-inner--icon">
                                                    <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                                                </span>
                                                <span class="btn-inner--text">Anular</span>
                                            </base-button>

                                        </template>
                                        <template v-else>
                                            <base-button icon size="sm" type="success" @click="RestaurarResponsable(param)">
                                                <span class="btn-inner--icon">
                                                    <b-icon icon="arrow-counterclockwise" aria-label="Help"></b-icon>
                                                </span>
                                                <span class="btn-inner--text">Restaurar</span>
                                            </base-button>
                                        </template>

                                        <base-button icon size="sm" type="default" @click="CargarDatosModificables(param)">
                                            <span class="btn-inner--icon">
                                                <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                                            </span>
                                            <span class="btn-inner--text">Modificar</span>
                                        </base-button>
                                    </template>
                                    <template v-slot:cell(estado)="data">
                                        <b class="text-danger" v-if="data.value=='1'">INACTVO</b>
                                        <b class="text-success" v-else>ACTIVO</b>
                                    </template>
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <template v-slot:footer>
                            <b-row v-if="!cargaResponsables">
                                <b-col lg="12">
                                    <b-pagination style="justify-content:flex-end;" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                                </b-col>
                            </b-row>
                        </template>
                    </card>
                </b-overlay>
            </b-col>
        </b-row>
    </b-container>
    <!--End container-->

    <b-modal id="modal-agregar-responsable" ref="modal-agregar-responsable" title="Registrar nuevo responsable" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetFormResponsable">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarResponsable)">
                <div class="pl-lg-3 pr-lg-3 ">
                    <b-row>
                        <b-col lg="4">
                            <validation-provider name="de tipo de documento" :rules="{ required: true }" v-slot="validationContext">
                                <b-form-group label="Tipo de Documento" class="labelx">
                                    <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" id="tipoDoc" v-model="datosPersona.tipodocumento" :options="comboTipoDocumento" size="sm" value-field="idTipoDocumento" text-field="Descripcion">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Número de documento" name="de número de documento" :rules="{ required: true,min:8,max:14,numeric:true }">
                                <b-overlay :show="iniciaCarga" opacity="0.5" spinner-small>
                                    <b-input-group>
                                        <b-form-input autocomplete="off" v-model="datosPersona.numeroDocumento" size="sm" placeholder="Ingrese su documento"></b-form-input>
                                        <b-input-group-append>
                                            <base-button size="sm" type="default" @click="apiDNI" :disabled="buscarDniBtn" >
                                                <b-icon icon="search" aria-label="Help"></b-icon>
                                            </base-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-overlay>
                            </base-input>

                            <!-- <validation-provider name="de numero de documento" :rules="{ required: true,min:8,max:14 }" v-slot="validationContext">
                                <b-form-group label="Número Documento" class="labelx">
                                    <b-input-group>
                                        <b-form-input :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" id="nroDoc" placeholder="Ingrese número documento" v-model="datosPersona.numeroDocumento" size="sm"></b-form-input>
                                        <b-input-group-append>
                                            <b-overlay :show="iniciaCarga" rounded opacity="0.5" spinner-small class="d-inline-block">
                                                <base-button size="sm" type="default" @click="apiDNI" :disabled="datosPersona.numeroDocumento.length == 0 || datosPersona.tipodocumento.length == 0">
                                                    <b-icon icon="search" aria-label="Help"></b-icon>
                                                </base-button>
                                            </b-overlay>
                                        </b-input-group-append>
                                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

                                    </b-input-group>
                                </b-form-group>
                            </validation-provider> -->
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Nombres" name="de nombres" :rules="{ required: true }" placeholder="Ingrese nombres" v-model="datosPersona.nombres" input-classes="form-control-sm" class="mb-1" :disabled="iniApiDNI"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Apellido paterno" name="de apellido paterno" :rules="{ required: true }" placeholder="Ingrese Apellido paterno" v-model="datosPersona.apellidoPaterno" input-classes="form-control-sm" :disabled="iniApiDNI"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Apellido materno" name="de apellido materno" :rules="{ required: true }" placeholder="Ingrese Apellido materno" v-model="datosPersona.apellidoMaterno" input-classes="form-control-sm" :disabled="iniApiDNI"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Correo" name="de correo" :rules="{ required: true,email:true }" placeholder="Ingrese correo" v-model="datosPersona.correo" input-classes="form-control-sm" :disabled="iniApiDNI"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Fecha de Nacimiento" name="de fecha de nacimiento" :rules="{ required: true }" v-model="datosPersona.fechaNacimiento" type="date" input-classes="form-control-sm" :disabled="iniApiDNI"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <validation-provider name="de estado civil" :rules="{ required: true }" v-slot="validationContext">
                                <b-form-group label="Estado Civil" class="labelx">
                                    <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" size="sm" :disabled="iniApiDNI" :options="comboEstadoCivil" value-field="idEstadoCivil" text-field="Descripcion" v-model="datosPersona.estadoCivil">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="4">
                            <validation-provider name="de sexo" :rules="{ required: true}" v-slot="validationContext ">
                                <b-form-group class="labelx" label="Sexo">
                                    <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" v-model="datosPersona.sexo" :options="comboSexo" size="sm" :disabled="iniApiDNI">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="12 text-right">
                            <b-button size="sm" variant="success" class="mb-2" type="submit">
                                Registrar Responsable
                            </b-button>
                            <b-button class="mb-2" size="sm" variant="danger" @click="$bvModal.hide('modal-agregar-responsable')">Cerrar</b-button>

                        </b-col>
                    </b-row>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>

    <b-modal id="modal-modificar-responsable" ref="modal-modificar-responsable" title="Modificar datos del responsable" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(ActualizarResponsable)">
                <div class="pl-lg-3 pr-lg-3 ">
                    <b-row>
                        <b-col lg="6">
                            <base-input label="Tipo de Documento" name="de tipo de documento " :rules="{ required: true }" placeholder="Ingrese tipo de documento" v-model="modifPersona.Desctipodocumento" input-classes="form-control-sm" disabled> </base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input label="Número de Documento" name="de numero de documento " :rules="{ required: true }" placeholder="Ingrese numero de documento" v-model="modifPersona.numeroDocumento" input-classes="form-control-sm" disabled> </base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input label="Apellido Paterno" name="de apellido paterno " :rules="{ required: true }" placeholder="Ingrese apellido paterno" v-model="modifPersona.apellidoPaterno" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input label="Apellido Materno" name="de apellido materno " :rules="{ required: true }" placeholder="Ingrese apellido Materno" v-model="modifPersona.apellidoMaterno" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input label="Nombres" name="de nombres " :rules="{ required: true }" placeholder="Ingrese nombres" v-model="modifPersona.nombres" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input label="Correo" name="de correo " :rules="{ required: true,email:true }" placeholder="Ingrese correo" v-model="modifPersona.correo" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Fecha de Nacimiento" name="de fecha de nacimiento " :rules="{ required: true }" type="date" placeholder="Ingrese Fecha de Nacimiento" v-model="modifPersona.fechaNacimiento" input-classes="form-control-sm"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <validation-provider name="de estado civil " :rules="{ required: true }" v-slot="validationContext">
                                <b-form-group label="Estado Civil" class="labelx">
                                    <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" v-model="modifPersona.estadoCivil" :options="comboEstadoCivil" size="sm" value-field="idEstadoCivil" text-field="Descripcion">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="4">
                            <validation-provider name="de sexo " :rules="{ required: true }" v-slot="validationContext">
                                <b-form-group label="Sexo" class="labelx">
                                    <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" v-model="modifPersona.sexo" :options="comboSexo" size="sm">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-form-group>
                                <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                        </b-col>

                        <b-col lg="12 text-right">
                            <b-button variant="success" size="sm" type="submit">Actualizar</b-button>
                            <b-button variant="danger" size="sm" @click="$bvModal.hide('modal-modificar-responsable')">Cerrar</b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    name: "responsables",
    components: {},
    data() {
        return {
            buscarDniBtn: true,
            buscarCliente: "",
            cargaResponsables: true,
            iniApiDNI: true,
            iniciaCarga: false,
            datosCliente: {
                razonSocial: "",
                idCliente: ""
            },
            datosPersona: {
                tipodocumento: null,
                estadoCivil: null,
                sexo: null,
                numeroDocumento: "",
                tipousuario: "3",
                apellidoPaterno: "",
                apellidoMaterno: "",
                nombres: "",
                correo: "",
                fechaNacimiento: "",
                idCliente: " ",
                usuario: "admin",
            },
            modifPersona: {
                tipodocumento: null,
                Desctipodocumento: null,
                estadoCivil: null,
                estado: null,
                sexo: null,
                numeroDocumento: "",
                apellidoPaterno: "",
                apellidoMaterno: "",
                nombres: "",
                correo: "",
                fechaNacimiento: "",
                idPersona: "",
                nombreCorto: "",
                estadoResponsable: "",
                usuario: "admin",
            },
            listaClientes: [],
            listaClientesFiltrada: [],
            listaResponsables: [],
            comboEstadoCivil: [],
            comboTipoDocumento: [],
            comboSexo: [{
                    value: "M",
                    text: "Masculino"
                },
                {
                    value: "F",
                    text: "Femenino"
                },
            ],
            camposAdministracion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "descTipoDoc",
                    label: "Tipo de documento",
                    class: "text-center "
                },
                {
                    key: "numeroDocumento",
                    label: "Número de documento",
                    class: "text-center"
                },
                {
                    key: "shortName",
                    label: "Nombre completo",
                    class: "text-center"
                },
                {
                    key: "FechaNacimiento",
                    label: "Fecha de Nacimiento",
                    class: "text-center"
                },
                {
                    key: "correo",
                    label: "Correo",
                    class: "text-center"
                },
                {
                    key: "estado",
                    label: "Estado",
                    class: "text-center"
                },
                {
                    key: "actions",
                    label: "Opciones",
                    class: "text-center"
                },
            ],
            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, {
                value: 99999,
                text: "Mostrar todo"
            }],

            filter: null,
            filterOn: [],
        };
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        ListarClientes() {
            let me = this
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/listar-clientes", {

                })
                .then(function (response) {
                    me.listaClientes = response.data;

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        onOptionClick(option) {
            let me = this
            me.datosCliente.razonSocial = option.razonSocial;
            for (var i = 0; i < me.listaClientes.length; i++) {
                if (me.listaClientes[i].razonSocial === option.razonSocial) {
                    me.datosPersona.idCliente = me.listaClientes[i].idCliente;
                    me.datosCliente.idCliente = me.listaClientes[i].idCliente;

                }
            }
            me.ListarResponsables();
        },
        apiDNI() {
            let me = this;
            let mi_token =
                "76afdc4b32381ff72ff7a4d827f7856bb850e6228adc9e9f6f1c74e114510f22";
            me.iniciaCarga = true;
            axios
                .get("https://apiperu.dev/api/dni/" + me.datosPersona.numeroDocumento, {
                    headers: {
                        Authorization: `Bearer ${mi_token}`
                    },
                    responseType: "json",
                })
                .then((response) => {
                    if (response.data.data) {
                        me.datosPersona.nombres = response.data.data.nombres;
                        me.datosPersona.apellidoPaterno = response.data.data.apellido_paterno;
                        me.datosPersona.apellidoMaterno = response.data.data.apellido_materno;
                    } else {
                        me.$notify({
                            verticalAlign: 'bottom',
                            horizontalAlign: 'right',
                            type: 'danger',
                            icon: 'emoji-frown',
                            message: response.data.message
                        });
                    }
                    me.iniApiDNI = false;
                    me.iniciaCarga = false;
                })
                .catch((error) => {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },

        ListarResponsables() {
            let me = this;
            me.cargaResponsables = true;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/listar-responsables", {
                        params: {
                            idCliente: me.datosCliente.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaResponsables = response.data;
                    me.filasTotales = me.listaResponsables.length
                    me.cargaResponsables = false;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        registrarResponsable() {
            let me = this;
            let registrarPersona = [];
            registrarPersona.push({
                persona: me.datosPersona
            });

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-responsable",
                    registrarPersona, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });
                    me.ListarResponsables();
                    me.resetFormResponsable();
                    me.$refs["modal-agregar-responsable"].hide();
                    me.$nextTick(() => {
                        me.$refs.observer.reset();
                    });

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });

        },
        resetFormResponsable() {
            let me = this;
            me.iniApiDNI = true
            me.datosPersona.tipodocumento = null
            me.datosPersona.estadoCivil = null
            me.datosPersona.sexo = null
            me.datosPersona.numeroDocumento = ""
            me.datosPersona.apellidoPaterno = ""
            me.datosPersona.apellidoMaterno = ""
            me.datosPersona.nombres = ""
            me.datosPersona.correo = ""
            me.datosPersona.fechaNacimiento = ""
        },
        obtenerEstadoCivil() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-estado-civil")
                .then(function (response) {
                    me.comboEstadoCivil = response.data;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        obtenerTipoDocumento() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-tipo-documento")
                .then(function (response) {
                    me.comboTipoDocumento = response.data;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        AnularResponsable(param) {
            let me = this
            let ENombreCompleto = param.item.shortName;
            me.$bvModal
                .msgBoxConfirm(
                    "Esta seguro que desea eliminar al administrador " +
                    ENombreCompleto +
                    " ? ", {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "master/desactivar-usuario", {
                                    IdPersona: param.item.idPersona,
                                    Estado: "1"
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarResponsables();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },
        RestaurarResponsable(param) {
            let me = this
            let ENombreCompleto = param.item.shortName;
            me.$bvModal
                .msgBoxConfirm(
                    "Esta seguro que desea restaurar al administrador " +
                    ENombreCompleto +
                    " ? ", {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "master/desactivar-usuario", {
                                    IdPersona: param.item.idPersona,
                                    Estado: "2"
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarResponsables();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },
        CargarDatosModificables(param) {
            let me = this
            me.modifPersona.idPersona = param.item.idPersona;
            me.modifPersona.tipodocumento = param.item.idTipoDocumento;
            me.modifPersona.Desctipodocumento = param.item.descTipoDoc;
            me.modifPersona.estadoCivil = param.item.idEstadoCivil;
            me.modifPersona.sexo = param.item.Sexo;
            me.modifPersona.numeroDocumento = param.item.numeroDocumento;
            me.modifPersona.apellidoPaterno = param.item.ApellidoPaterno;
            me.modifPersona.apellidoMaterno = param.item.ApellidoMaterno;
            me.modifPersona.nombres = param.item.Nombres;
            me.modifPersona.correo = param.item.correo;
            me.modifPersona.fechaNacimiento = param.item.FechaNacimiento;
            me.modifPersona.nombreCorto = param.item.shortName;
            me.modifPersona.idCliente = me.datosCliente.idCliente;
            me.$refs["modal-modificar-responsable"].show();
        },
        ActualizarResponsable() {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/actualizar-responsable",
                    me.modifPersona, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });
                    me.ListarResponsables();
                    me.$refs["modal-modificar-responsable"].hide();

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        }

    },
     watch: {
          'datosPersona.numeroDocumento': function (val) {
           if (val.length >= 8 && val.length <= 14){
               this.buscarDniBtn = false
           }
           else{
               this.buscarDniBtn = true
           }
        }
        },
    computed: {
        criteria() {
            return this.buscarCliente.trim().toLowerCase()
        },
        OpcionesDisponibles() {
            const criteria = this.criteria

            const optionsx = this.listaClientes.filter(opt => this.listaClientesFiltrada.indexOf(opt) === -1)
            if (criteria) {
                return optionsx.filter(opt => opt.razonSocial.toLowerCase().indexOf(criteria) > -1);
            }
            return optionsx
        },
        MensajeBusqueda() {
            if (this.criteria && this.OpcionesDisponibles.length === 0) {
                return 'Cliente no encontrado, pruebe con otro criterio de busqueda'
            }
            return ''
        }
    },
    mounted() {
        if (localStorage.usuario) {
            let me = this
            var lsUsuario = JSON.parse(localStorage.usuario);
            me.ListarClientes();
            me.obtenerEstadoCivil();
            me.obtenerTipoDocumento();
        }
    }
};
</script>
