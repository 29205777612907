<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
            <b-col xl="12 " class="order-xl-2">
                <b-card no-body class="card-profile" alt="Image placeholder" img-top>
                    <b-card-body>
                        <b-row>
                            <b-col lg="5 text-center" style="align-self: center;">
                                <template>
                                    <div>
                                        <b-img class=" img-responsive img-fluid" :alt="datosCapacitacion.nombreCapacitacion" center rounded width="400px" :src="datosCapacitacion.archivoMeta" />

                                        <!-- <img width="400px" :src="datosCapacitacion.archivoMeta" rounded  class="img img-responsive img-fluid"  :alt="datosCapacitacion.nombreCapacitacion" > -->
                                    </div>
                                </template>
                            </b-col>
                            <b-col lg="7">
                                <div class="text-left">
                                    <div class="h4 mt-4">
                                        {{datosCapacitacion.nombre}}
                                    </div>

                                    <div class="h5 font-weight-300" align="justify">
                                        {{datosCapacitacion.descripcion}}
                                    </div>
                                    <hr class="my-3">
                                    <div class="h4 font-weight-300">
                                        <b-badge class="defaultx">{{ datosCapacitacion.nombre_tipo }}</b-badge>
                                    </div>
                                    <div class="h5 font-weight-300">
                                        <base-button icon size="sm" type="default" v-b-modal.modal-modificar-capacitacion>
                                            <span class="btn-inner--icon">
                                                <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                                            </span>
                                            <span class="btn-inner--text">Actualizar Información </span>
                                        </base-button>
                                        <base-button icon size="sm" type="default" v-b-modal.modal-vista-previa>
                                            <span class="btn-inner--icon">
                                                <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                                            </span>
                                            <span class="btn-inner--text"> Vista Previa</span>
                                        </base-button>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </base-header>
    <!--End head-->

    <b-container fluid class="mt--8 mb-5">
        <div class="mt-4">
            <b-row>
                <b-col xl="12 ">
                    <card header-classes="bg-transparent" class="mt-4">
                        <b-row align-v="center" slot="header">
                            <b-col>
                                <h5 class="h3 mb-0"> Evaluaciones</h5>
                            </b-col>
                            <b-col class="text-right">
                                <span class="btn btn-sm btn-primary" v-b-modal.modal-asignar-evaluacion>
                                    <b-icon icon="person-plus-fill" aria-hidden="true"></b-icon> Agregar Evaluación
                                </span>
                            </b-col>
                        </b-row>
                        <b-table sort-icon-left show-empty small responsive outlined :items="listaEvaluaciones" :fields="camposEvaluacion" :current-page="paginaActualEva" :per-page="porPaginaEva" empty-text="Aún no hay evaluaciones en esta sección." :busy="cargandoArchivos">
                            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                            <template v-slot:cell(opciones)="param">
                                <template v-if="param.item.estado == 2">
                                    <base-button icon size="sm" type="danger" @click="EliminarEvaluacion(param)">
                                        <span class="btn-inner--icon">
                                            <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                                        </span>
                                        <span class="btn-inner--text">Eliminar </span>
                                    </base-button>
                                </template>
                                <template v-if="param.item.estado == 1">
                                    <base-button icon size="sm" type="success" @click="RestaurarEvaluacion(param)">
                                        <span class="btn-inner--icon">
                                            <b-icon icon="arrow-counterclockwise" aria-label="Help"></b-icon>
                                        </span>
                                        <span class="btn-inner--text">Restaurar </span>
                                    </base-button>
                                </template>
                            </template>
                        </b-table>
                        <template v-slot:footer>
                            <b-row v-if="!cargarEvaluacion">
                                <b-col lg="12">
                                    <b-pagination style="justify-content:flex-end;" v-model="paginaActualEva" :total-rows="filasTotalesEva" :per-page="porPaginaEva" first-number last-number></b-pagination>
                                </b-col>
                            </b-row>
                        </template>
                    </card>
                </b-col>

                <b-col xl="12 ">
                    <card header-classes="bg-transparent" class="mt-4">
                        <b-row align-v="center" slot="header">
                            <b-col>
                                <h5 class="h3 mb-0">Archivos</h5>
                            </b-col>
                        </b-row>
                        <base-input input-classes="form-control-sm">
                            <b-form-file v-model="ArchivoCapacitacion" class="form-control-sm" size="sm" placeholder="Elija un archivo o arrástrelo aquí..." @change="procesarArchivo" ref="input-archivos" browse-text="Subir"></b-form-file>
                        </base-input>
                        <b-alert dismissible :show="emitirAlerta" variant="danger">{{mensajeAlerta}}</b-alert>

                        <b-table sort-icon-left show-empty small responsive outlined :items="listaArchivos" :fields="campoArchivos" :current-page="paginaActualArc" :per-page="porPaginaArc" empty-text="Aún no hay archivos en esta sección." :busy="cargandoArchivos">
                            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                            <template v-slot:cell(opciones)="param">
                                <base-button icon size="sm" type="success" @click="descargarArchivo(param)">
                                    <span class="btn-inner--icon">
                                        <b-icon icon="cloud-download" aria-label="Help"></b-icon>
                                    </span>
                                    <span class="btn-inner--text">Descargar </span>
                                </base-button>
                                <base-button icon size="sm" type="danger" @click="eliminarArchivo(param)">
                                    <span class="btn-inner--icon">
                                        <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                                    </span>
                                    <span class="btn-inner--text">Eliminar </span>
                                </base-button>
                            </template>
                            <template v-slot:table-busy>
                                <div style="  color: #525f7f !important; " class="text-center text-secondary my-2">
                                    <b-spinner class="align-middle" small></b-spinner>
                                    <strong> Cargando ...</strong>
                                </div>
                            </template>
                        </b-table>
                        <template v-slot:footer>
                            <b-row v-if="!cargandoArchivos">
                                <b-col lg="12">
                                    <b-pagination style="justify-content:flex-end;" v-model="paginaActualArc" :total-rows="filasTotalesArc" :per-page="porPaginaArc" first-number last-number></b-pagination>
                                </b-col>
                            </b-row>
                        </template>
                    </card>
                </b-col>
            </b-row>
        </div>
        <!--End tables-->
    </b-container>

    <b-modal id="modal-asignar-evaluacion" ref="modal-asignar-evaluacion" title="Asignar nueva evaluacion" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetModalEvaluaciones">
        <b-row>
            <b-col xl="12">
                <b-table :items="listaEvaluacionesModal" sticky-header="300px" class="mt-2" small outlined selectable select-mode="multi" @row-selected="onRowSelected" :fields="camposEvaluacionSmall" responsive show-empty empty-text="No se encontraron evaluaciones">
                    <template #cell(index)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only">Seleccionado</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                            <span class="sr-only">No seleccionado</span>
                        </template>
                    </template>
                    <template v-slot:cell(estado)="data">
                        <b class="text-danger" v-if="data.value=='1'">INACTVO</b>
                        <b class="text-success" v-else>ACTIVO</b>
                    </template>
                </b-table>
            </b-col>
            <b-col lg="12 text-right">
                <b-button size="sm" variant="success" class="mb-2" @click="AsignarEvaluacion()">
                    Añadir Evaluaciones
                </b-button>
                <b-button class="mb-2" size="sm" variant="danger" @click="$bvModal.hide('modal-asignar-evaluacion')">Cerrar</b-button>
            </b-col>
        </b-row>
    </b-modal>
    <b-modal id="modal-vista-previa" ref="modal-vista-previa" :title="datosCapacitacion.nombre" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetModalEvaluaciones">
        <b-row>
            <b-col xl="12" class=" text-center">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe :src="datosCapacitacion.urlArchivo+'&postMessageEvents=true'" width="576" height="420" scrolling="no" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
            </b-col>
        </b-row>
    </b-modal>

    <b-modal class="bg-secondary" id="modal-modificar-capacitacion" ref="modal-modificar-capacitacion" title="Actualizar Información" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(ActualizarCapacitacion)">
                <div class="pl-lg-3 pr-lg-3 ">
                    <b-row>
                        <b-col lg="6">
                            <validation-provider name="de tipo de capacitación" :rules="{ required: true }" v-slot="validationContext">
                                <base-input label="Tipo de Capacitación">
                                    <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" id="tipoDoc" v-model="modifDatosCapacitacion.idTipoCapacitacion" :options="listaTipoCapacitacion" size="sm" value-field="idTipoCapacitacion" text-field="Nombre">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </base-input>
                            </validation-provider>
                        </b-col>

                        <b-col lg="6">
                            <base-input label="Nombre" name="de nombre" :rules="{ required: true }" placeholder="Ingrese Nombre de capacitación" input-classes="form-control-sm" v-model="modifDatosCapacitacion.nombre"> </base-input>
                        </b-col>

                        <b-col lg="6">
                            <base-input label="Url de capacitacion" name="de capacitacion" :rules="{ required: true }" placeholder="Ingrese enlace" input-classes="form-control-sm" v-model="modifDatosCapacitacion.urlArchivo"> </base-input>
                        </b-col>

                        <!-- <b-col lg="6">
                            <base-input label="Url de portada" name="de portada" :rules="{ required: true }" placeholder="Ingrese enlace" input-classes="form-control-sm" v-model="modifDatosCapacitacion.archivoMeta"> </base-input>
                        </b-col> -->
                        <b-col lg="6">
                            <!-- <validation-provider :rules="{required: true}" name="portada" v-slot="validationContext"> -->
                                <b-form-group label="Portada:" class=" labelx mb-2">
                                    <b-form-file class="form-control-sm mb-3" size="sm"  v-model="modifDatosCapacitacion.archivoMeta" placeholder="Elija un archivo o arrástrelo aquí..." accept=".png, .jpg, .jpge" browse-text="Subir" ref="file" @change="handleFileUpload"></b-form-file>
                                    <!-- <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
                                </b-form-group>
                            <!-- </validation-provider> -->
                        </b-col>
                        <b-col lg="12">
                            <base-input label="Descripcion">
                                <b-form-textarea rows="3" placeholder="Ingrese Descripcion" v-model="modifDatosCapacitacion.descripcion"></b-form-textarea>
                            </base-input>
                        </b-col>

                        <b-col lg="12 text-right">
                            <b-button variant="success" size="sm" type="submit">Actualizar</b-button>
                            <b-button variant="danger" size="sm" @click="$bvModal.hide('modal-modificar-capacitacion')">Cerrar</b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    name: "MantenimientoCapacitacion",
    components: {

    },
    data() {
        return {
            ArchivoCapacitacion: null,
            idCapacitacion: "1",
            idCliente: "",
            cargandoArchivos: false,
            cargarEvaluacion: false,
            emitirAlerta: false,
            mensajeAlerta: "",
            usuario: "",
            listaArchivos: [],
            listaEvaluaciones: [],
            listaEvaluacionesModal: [],
            listaEvaluacionesModalSeleccionadas: [],
            listaTipoCapacitacion: [],
            datosCapacitacion: {
                idCapacitacion: "",
                nombre: "",
                descripcion: "",
                urlArchivo: "",
                archivoMeta: "",
                nombre_tipo: "",
            },
            modifDatosCapacitacion: {
                idCapacitacion: "",
                nombre: "",
                descripcion: "",
                urlArchivo: "",
                urlPortada: "",
                archivoMeta: null,
                idTipoCapacitacion: "",
            },
            campoArchivos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "nombreArchivo",
                    label: "Nombre",
                    class: "text-center"
                },
                {
                    key: "mimetype",
                    label: "Tipo",
                    class: "text-center"
                },
                {
                    key: "size",
                    label: "Tamaño",
                    class: "text-center"
                },
                {
                    key: "fechaCreacion",
                    label: "Fecha",
                    class: "text-center"
                },
                {
                    key: "opciones",
                    class: "text-center"
                },
            ],
            camposEvaluacion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center"
                },
                {
                    key: "n_preguntas",
                    label: "Preguntas disponibles",
                    class: "text-center"
                },

                {
                    key: "opciones",
                    label: "opciones",
                    class: "text-center"
                },
            ],
            camposEvaluacionSmall: [{
                    key: "index",
                    label: "N°",
                    class: "text-center pointerx tdx"
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center pointerx"
                },
                {
                    key: "fecha_creacion",
                    label: "Fecha",
                    class: "text-center pointerx"
                },
                {
                    key: "n_preguntas",
                    label: "Preguntas disponibles",
                    class: "text-center pointerx"
                },
                {
                    key: "estado",
                    label: "Estado",
                    class: "text-center pointerx"
                },
            ],
            filasTotalesArc: 1,
            paginaActualArc: 1,
            porPaginaArc: 5,

            filasTotalesEva: 1,
            paginaActualEva: 1,
            porPaginaEva: 5,

        };
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(png|jpg|jpeg)$/i)) {
                    this.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Formato de archivo no admitido.'
                    });
                    this.$refs["file"].reset();
                    return;
                }
                this.modifDatosCapacitacion.archivoMeta = this.$refs.file.files[0];
            }
        },
        onRowSelected(items) {
            let me = this
            items.forEach((a) => {
                a.id_capacitacion = me.datosCapacitacion.idCapacitacion
            })
            me.listaEvaluacionesModalSeleccionadas = items
        },
        resetModalEvaluaciones() {
            let me = this
            me.listaEvaluacionesModalSeleccionadas = []
        },
        CargarDatosCapacitacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "administrador/cargar-datos-capacitacion", {
                        params: {
                            idCapacitacion: me.datosCapacitacion.idCapacitacion
                        }
                    }
                )
                .then(function (response) {
                    if (response) {
                        me.datosCapacitacion.nombre = response.data[0].nombre
                        me.datosCapacitacion.descripcion = response.data[0].descripcion
                        me.datosCapacitacion.nombre_tipo = response.data[0].nombre_tipo
                        me.datosCapacitacion.archivoMeta = response.data[0].portada
                        me.datosCapacitacion.urlArchivo = response.data[0].url

                        me.modifDatosCapacitacion.idCapacitacion = response.data[0].idCapacitacion
                        me.modifDatosCapacitacion.nombre = response.data[0].nombre
                        me.modifDatosCapacitacion.descripcion = response.data[0].descripcion
                        me.modifDatosCapacitacion.idTipoCapacitacion = response.data[0].idTipoCapacitacion
                        me.modifDatosCapacitacion.nombre_tipo = response.data[0].nombre_tipo
                        // me.modifDatosCapacitacion.archivoMeta = response.data[0].portada
                        me.modifDatosCapacitacion.urlArchivo = response.data[0].url

                         me.modifDatosCapacitacion.urlPortada = response.data[0].portada
                        me.ListarArchivos();
                        me.ListarEvaluacionesCapacitacion();
                    }
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ObtenerTipoCapacitacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/listar-tipo-capacitacion")
                .then(function (response) {
                    if (response) {
                        me.listaTipoCapacitacion = response.data;
                    }
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        procesarArchivo(e) {
            let me = this;
            /*if (!e.target.files[0].name.match(/\.(xlsx|xls|csv)$/i)) {
              alert("Tipo de archivo no soportado");
              return;
            }*/
            me.$bvModal
                .msgBoxConfirm(
                    "¿Esta seguro que desea subir el archivo " +
                    e.target.files[0].name +
                    "?", {
                        size: "sm",
                        buttonSize: "sm",
                        okTitle: "Subir",
                        cancelTitle: "Cancelar",
                        cancelVariant: "danger",
                        okVariant: "success",
                        headerClass: "p-2 border-bottom-0",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                .then((value) => {
                    if (value) {
                        let tipoArchvivo = 0;
                        if (me.ArchivoCapacitacion) {
                            tipoArchvivo = 1;
                            me.cargandoArchivos = true;
                        }
                        const formData = new FormData();
                        formData.append("file", e.target.files[0]);
                        formData.append("idCliente", me.idCliente);
                        formData.append("idCapacitacion", me.datosCapacitacion.idCapacitacion);
                        formData.append("nombreCapacitacion", me.datosCapacitacion.nombre);
                        formData.append("usuario", me.usuario);
                        formData.append("idTipoArchivo", tipoArchvivo);
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "administrador/upload",
                                formData
                            )
                            .then(async (res) => {
                                if (res.data.resultado == 0) {
                                    me.mensajeAlerta = res.data.mensaje;
                                    if (me.ArchivoCapacitacion) {
                                        me.emitirAlerta = true;
                                        me.$refs["input-archivos"].reset();
                                    }
                                } else {
                                    me.$refs["input-archivos"].reset();
                                    await me.ListarArchivos();
                                }
                                me.cargandoArchivos = false;
                            })
                            .catch((err) => {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    } else {
                        me.$refs["input-archivos"].reset();
                    }
                });
        },
        ListarArchivos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "administrador/listar-archivos", {
                        params: {
                            idCapacitacion: me.datosCapacitacion.idCapacitacion,
                            idCliente: me.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.listaArchivos = response.data.filter((tipo) => tipo.idTipoArchivo == 1);
                    me.filasTotalesArc = me.listaArchivos.length
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ListarEvaluacionesModal() {
            let me = this;
            me.cargarEvaluacion = true;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/listar-evaluacion-asignacion", {
                        params: {
                            idCapacitacion: me.datosCapacitacion.idCapacitacion,
                        },
                    }
                )
                .then(function (response) {
                    me.listaEvaluacionesModal = response.data;
                    me.cargarEvaluacion = false;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ListarEvaluacionesCapacitacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "administrador/listar-evaluacion-capacitacion", {
                        params: {
                            idCapacitacion: me.datosCapacitacion.idCapacitacion,
                        },
                    }
                )
                .then(function (response) {
                    me.listaEvaluaciones = response.data
                    me.filasTotalesEva = me.listaEvaluaciones.length
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ActualizarCapacitacion() {
            let me = this;
            const formData = new FormData();
            formData.append("file", me.modifDatosCapacitacion.archivoMeta);
            formData.append("folder", 'logo');
            formData.append("idCapacitacion", me.modifDatosCapacitacion.idCapacitacion);

            formData.append("nombre", me.modifDatosCapacitacion.nombre);
            formData.append("descripcion", me.modifDatosCapacitacion.descripcion);
            formData.append("urlArchivo", me.modifDatosCapacitacion.urlArchivo);
            formData.append("urlPortada", me.modifDatosCapacitacion.urlPortada);

            formData.append("idTipoCapacitacion", me.modifDatosCapacitacion.idTipoCapacitacion);
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/actualizar-capacitacion",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then((response) => {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });

                    me.CargarDatosCapacitacion();
                    me.$refs["modal-modificar-capacitacion"].hide();
                })
                .catch((err) => {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });

            // let me = this
            // let actualizar = [];
            // actualizar.push({
            //     capacitacion: me.modifDatosCapacitacion
            // });
            // axios
            //     .post(
            //         CONSTANTES.URL_RUTA_SERVICIOS + "administrador/actualizar-capacitacion",
            //         actualizar, {
            //             headers: {
            //                 Authorization: `Bearer ${localStorage.token}`
            //             },
            //         }
            //     )
            //     .then(function (response) {
            //         let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
            //         let color = response.data.resultado == 1 ? "success" : "danger";

            //         me.$notify({
            //             verticalAlign: 'bottom',
            //             horizontalAlign: 'right',
            //             type: color,
            //             icon: iconn,
            //             message: response.data.mensaje
            //         });

            //         me.CargarDatosCapacitacion();
            //         me.$refs["modal-modificar-capacitacion"].hide();

            //     })
            //     .catch(function (error) {
            //         me.$notify({
            //             verticalAlign: 'bottom',
            //             horizontalAlign: 'right',
            //             type: 'danger',
            //             icon: 'emoji-frown',
            //             message: 'Error, algo salió mal.'
            //         });
            //     });
        },
        descargarArchivo(param) {
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/descargar-archivo", {
                        params: {
                            ruta: param.item.rutaNube,
                            archivo: param.item.nombreArchivo,
                            idArchivo: param.item.idArchivo,
                        },
                    }
                )
                .then(function (response) {
                    window.open(response.data);
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        eliminarArchivo(param) {
            let me = this;
            me.$bvModal
                .msgBoxConfirm(
                    "¿Está seguro que desea eliminar el archivo " +
                    param.item.nombreArchivo +
                    "?", {
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        size: "sm",
                        buttonSize: "sm",
                        headerClass: "p-2 border-bottom-0",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    }
                )
                .then((value) => {
                    if (value) {
                        if (param.item.idTipoArchivo == 1) {
                            me.cargandoArchivos = true;
                        }

                        axios.defaults.headers.common[
                            "Authorization"
                        ] = `Bearer ${localStorage.token}`;
                        axios
                            .get(
                                CONSTANTES.URL_RUTA_SERVICIOS +
                                "administrador/eliminar-archivo", {
                                    params: {
                                        idArchivo: param.item.idArchivo,
                                    },
                                }
                            )
                            .then(function (response) {
                                if (response) {
                                    me.ListarArchivos();
                                    if (param.item.idTipoArchivo == 1) {
                                        me.cargandoArchivos = false;
                                    }
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },
        AsignarEvaluacion() {
            let me = this
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/asignar-evaluacion",
                    me.listaEvaluacionesModalSeleccionadas, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });
                    me.$refs["modal-asignar-evaluacion"].hide();
                    me.ListarEvaluacionesCapacitacion();
                    me.ListarEvaluacionesModal();
                    me.resetModalEvaluaciones()

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        EliminarEvaluacion(param) {
            let me = this
            let NombreEvaluacion = param.item.nombre;
            me.$bvModal
                .msgBoxConfirm(
                    "Esta seguro que desea quitar la evaluación " +
                    NombreEvaluacion +
                    " ? ", {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "administrador/eliminar-evaluacion-capacitacion", {
                                    id_capa_evaluacion: param.item.id_capacitacion_evaluacion,
                                    estado: "1"
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarEvaluacionesCapacitacion();
                                    me.ListarEvaluacionesModal();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },
        RestaurarEvaluacion(param) {
            let me = this
            let NombreEvaluacion = param.item.nombre;
            me.$bvModal
                .msgBoxConfirm(
                    "Esta seguro que desea quitar la evaluación " +
                    NombreEvaluacion +
                    " ? ", {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "administrador/eliminar-evaluacion-capacitacion", {
                                    id_capa_evaluacion: param.item.id_capacitacion_evaluacion,
                                    estado: "2"
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarEvaluacionesCapacitacion();
                                    me.ListarEvaluacionesModal();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        }
    },
    watch: {

    },
    mounted() {
        if (localStorage.usuario) {
            let me = this;
            var lsUsuario = JSON.parse(localStorage.usuario)
            me.idCliente = lsUsuario.nIdCliente
            me.usuario = lsUsuario.username
            me.datosCapacitacion.idCapacitacion = me.$route.params.idCapacitacion
            me.CargarDatosCapacitacion();
            me.ObtenerTipoCapacitacion();
            me.ListarEvaluacionesModal();
        }

        //        this.ListarArchivos();

    }
};
</script>
