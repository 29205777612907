<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>

        </b-row>
    </base-header>
    <!--End head-->

    <!--ontainer-->
    <b-container fluid class="mt--8 mb-5">
        <div class="mt-4">
            <b-row>
                <b-col xl="12">
                    <b-overlay :show="cargaCapacitaciones" rounded="lg" opacity="0.5">
                        <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                                <b-spinner type="grow" variant="dark"></b-spinner>
                                <b-spinner small type="grow" variant="secondary"></b-spinner>
                            </div>
                        </template>
                        <card header-classes="bg-transparent">
                            <template v-slot:header>
                                <b-row align-v="center" slot="header">
                                    <b-col>
                                        <h3 class="mb-0">Capacitaciones </h3>
                                    </b-col>
                                    <!-- <b-col class="text-right">
                                        <span class="btn btn-sm btn-warning" v-b-modal.modal-registro-categoria>
                                            <b-icon icon="bookmarks-fill" aria-hidden="true"></b-icon> Agregar Categoría
                                        </span>
       <span class="btn btn-sm btn-warning" v-b-modal.modal-registro-categoria>
                                            <b-icon icon="bookmarks-fill" aria-hidden="true"></b-icon> Agregar Categoría
                                        </span>

                                    </b-col> -->
                                    <b-col class="text-right">
                                        <!-- <span class="btn btn-sm btn-primary" v-b-modal.modal-registro-capacitacion>
                                            <b-icon icon="bookmark-plus-fill" aria-hidden="true"></b-icon> Agregar Capacitación
                                        </span> -->

                                        <b-dropdown size="sm" icon="bookmark-plus-fill" variant="primary" class="m-md-2 bg-secondary">
                                            <template v-slot:button-content>
                                                <b-icon icon="bookmark-plus-fill" aria-hidden="true"></b-icon> Agregar nuevo item
                                            </template>
                                            <template>
                                                <b-dropdown-item size="sm" v-b-modal.modal-registro-capacitacion>
                                                    <b-icon icon="bookmarks-fill" aria-hidden="true"></b-icon>
                                                    <span>Capacitación</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item size="sm" v-b-modal.modal-registro-categoria>
                                                    <b-icon icon="bookmark-plus-fill" aria-hidden="true"></b-icon>
                                                    <span>Categoría</span>
                                                </b-dropdown-item>
                                            </template>
                                        </b-dropdown>
                                    </b-col>

                                </b-row>
                            </template>
                            <b-row>
                                <b-col lg="4">
                                    <base-input v-if="!cargaCapacitaciones">
                                        <b-input-group class="text-right" size="xl">
                                            <b-input-group-prepend is-text>
                                                <b-icon icon="search"></b-icon>
                                            </b-input-group-prepend>
                                            <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                            <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                        </b-input-group>
                                    </base-input>
                                </b-col>
                                <b-col lg="4">

                                </b-col>
                                <b-col lg="4">
                                    <base-input v-if="!cargaCapacitaciones">
                                        <b-form-select size="xl" v-model="porPagina" id="porPaginaSelect" :options="paginaOpciones"></b-form-select>
                                    </base-input>
                                </b-col>

                                <b-col lg="12">
                                    <b-table sort-icon-left show-empty small responsive outlined :items="listaCapacitaciones" :fields="camposCapacitaciones" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay capacitaciones en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                        <template v-slot:cell(estado)="data">
                                            <b class="text-danger" v-if="data.value=='1'">INACTVO</b>
                                            <b class="text-success" v-else>ACTIVO</b>
                                        </template>
                                        <template v-slot:cell(actions)="param">
                                            <!--   <template v-if="param.item.estado == 2">
                                    <base-button icon size="sm" type="danger" @click="AnularCapacitacion(param)">
                                        <span class="btn-inner--icon">
                                            <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                                        </span>
                                        <span class="btn-inner--text">Anular</span>
                                    </base-button>

                                </template>
                                <template v-else>
                                    <base-button icon size="sm" type="success" @click="RestaurarCapacitacion(param)">
                                        <span class="btn-inner--icon">
                                            <b-icon icon="arrow-counterclockwise" aria-label="Help"></b-icon>
                                        </span>
                                        <span class="btn-inner--text">Restaurar</span>
                                    </base-button>
                                </template>-->

                                            <router-link :to="{name:'mantenimiento capacitación',params: {idCapacitacion: param.item.idCapacitacion}}" class="text-light">
                                                <base-button icon size="sm" type="default">
                                                    <span class="btn-inner--icon">
                                                        <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                                                    </span>
                                                    <span class="btn-inner--text">Modificar</span>
                                                </base-button>
                                            </router-link>

                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                            <template v-slot:footer>
                                <b-row v-if="!cargaCapacitaciones">
                                    <b-col lg="12">
                                        <b-pagination style="justify-content:flex-end;" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                                    </b-col>
                                </b-row>
                            </template>
                        </card>
                    </b-overlay>
                </b-col>
            </b-row>
        </div>
    </b-container>
    <!--End container-->
    <b-modal class="bg-secondary" id="modal-registro-capacitacion" ref="modal-registro-capacitacion" title="Agregar Nueva Capacitación" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetModalCapacitacion">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(RegistrarCapacitacion)">
                <div class="pl-lg-3 pr-lg-3 ">
                    <b-row>
                        <b-col lg="6">
                            <validation-provider name="de tipo de capacitación" :rules="{ required: true }" v-slot="validationContext">
                                <base-input label="Tipo de Capacitación">
                                    <b-form-select :state="getValidationState(validationContext)" id="tipoDoc" v-model="datosCapacitacion.idTipoCapacitacion" :options="listaTipoCapacitacion" size="sm" value-field="idTipoCapacitacion" text-field="Nombre">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </base-input>
                            </validation-provider>
                        </b-col>
                        <b-col lg="6">
                            <base-input label="Nombre" name="de nombre" :rules="{ required: true }" placeholder="Ingrese Nombre de capacitación" input-classes="form-control-sm" v-model="datosCapacitacion.nombre"> </base-input>
                        </b-col>

                        <b-col lg="6">
                            <!--    <b-form-file placeholder="Seleccione el archivo" size="sm" drop-placeholder="Suelte aqui..."></b-form-file>-->
                            <base-input label="Url de capacitacion" name="de capacitacion" :rules="{ required: true }" placeholder="Ingrese enlace" input-classes="form-control-sm" v-model="datosCapacitacion.urlArchivo"> </base-input>
                        </b-col>
                        <!-- <b-col lg="6">
                            <base-input label="Url de portada" name="de portada" placeholder="Ingrese enlace" input-classes="form-control-sm" v-model="datosCapacitacion.urlPortada"> </base-input>
                        </b-col> -->
                        <b-col lg="6">
                            <validation-provider :rules="{required: true}" name="portada" v-slot="validationContext">
                                <b-form-group label="Portada:" class=" labelx mb-2">
                                    <b-form-file class="form-control-sm mb-3" size="sm" :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" v-model="datosCapacitacion.archivoMeta" placeholder="Elija un archivo o arrástrelo aquí..." accept=".png, .jpg, .jpge" browse-text="Subir" ref="file" @change="handleFileUpload"></b-form-file>
                                    <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="6">
                            <base-input label="Descripcion">
                                <b-form-textarea rows="3" placeholder="Ingrese Descripcion" v-model="datosCapacitacion.descripcion"></b-form-textarea>
                            </base-input>
                        </b-col>
                        <b-col lg="12  text-right">
                            <b-button size="sm" variant="success" class="mb-2" type="submit">
                                Registrar Capacitación
                            </b-button>
                            <b-button class="mb-2" size="sm" variant="danger" @click="$bvModal.hide('modal-registro-capacitacion')">Cerrar</b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>

    <b-modal class="bg-secondary" id="modal-registro-categoria" ref="modal-registro-categoria" title="Agregar Nueva categoria" size="sm" border-variant="default" no-close-on-esc hide-footer no-close-on-backdrop @show="resetModalCategoria">
        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form role="form" @submit.prevent="handleSubmit(RegistrarCategoria)">
                <b-row>
                    <b-col lg="12">
                        <base-input label="Nombre de la categoría" name="de nombre de la categoria" :rules="{ required: true }" placeholder="Ingrese nombre de la categoria" input-classes="form-control-sm" v-model="datosCategoria.nombre"> </base-input>
                    </b-col>
                    <b-col lg="12  text-right">
                        <b-button size="sm" variant="success" class="mb-3" type="submit">
                            Registrar Categoría
                        </b-button>
                        <b-button class="mb-3" size="sm" variant="danger" @click="$bvModal.hide('modal-registro-categoria')">Cerrar</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <b-table sort-icon-left sticky-header="300px" show-empty small responsive outlined :items="listaTipoCapacitacion" :fields="camposTipoCapacitacion" empty-text="Aún no hay categorias en esta sección." @show="resetModalCategoria">
            <template v-slot:cell(index)="data">{{ data.index + 1 }}
            </template>
        </b-table>
    </b-modal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    name: "capacitaciones",
    components: {},
    data() {
        return {
            camposTipoCapacitacion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "Nombre",
                    label: "Categoría",
                    class: "text-center"
                }
            ],
            cargaCapacitaciones: true,
            datosCategoria: {
                nombre: ""
            },
            datosCapacitacion: {
                nombre: "",
                descripcion: "",
                urlArchivo: "",
                archivoMeta: null,
                idTipoCapacitacion: null,
            },
            camposCapacitaciones: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },

                {
                    key: "nombre_tipo",
                    label: "Tipo",
                    class: "text-center",
                },
                {
                    key: "estado",
                    label: "Estado",
                    class: "text-center",
                },
                {
                    key: "actions",
                    label: "opciones",
                    class: "text-center",
                },
            ],

            listaTipoCapacitacion: [],
            listaCapacitaciones: [],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, {
                value: 99999,
                text: "Mostrar todo"
            }],

            filter: null,
            filterOn: [],
        };

    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(png|jpg|jpeg)$/i)) {
                    this.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Formato de archivo no admitido.'
                    });
                    this.$refs["file"].reset();
                    return;
                }
                this.datosCapacitacion.archivoMeta = this.$refs.file.files[0];
            }
        },
        ObtenerTipoCapacitacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/listar-tipo-capacitacion")
                .then(function (response) {
                    if (response) {
                        me.listaTipoCapacitacion = response.data;
                    }
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ListarCapacitaciones() {
            let me = this;
            me.cargaCapacitaciones = true
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "administrador/listar-capacitaciones")
                .then(function (response) {
                    if (response) {
                        me.listaCapacitaciones = response.data
                        me.filasTotales = me.listaCapacitaciones.length
                        me.cargaCapacitaciones = false
                    }
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },

        resetModalCapacitacion() {
            let me = this;
            me.datosCapacitacion.nombre = "",
                me.datosCapacitacion.descripcion = "",
                me.datosCapacitacion.urlArchivo = "",
                me.datosCapacitacion.archivoMeta = null,
                me.datosCapacitacion.idTipoCapacitacion = null
        },
        resetModalCategoria() {
            let me = this
            me.datosCategoria.nombre = ""
        },
        RegistrarCapacitacion() {

            let me = this;
            const formData = new FormData();
            formData.append("file", me.datosCapacitacion.archivoMeta);
            formData.append("folder", 'logo');
            formData.append("nombre", me.datosCapacitacion.nombre);
            formData.append("urlArchivo", me.datosCapacitacion.urlArchivo);

            formData.append("descripcion", me.datosCapacitacion.descripcion);
            formData.append("idTipoCapacitacion", me.datosCapacitacion.idTipoCapacitacion);
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/registrar-capacitacion",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then((response) => {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });
                    me.ListarCapacitaciones();
                    me.$refs["modal-registro-capacitacion"].hide();
                })
                .catch((err) => {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
            // let me = this
            // let registrar = [];
            // registrar.push({
            //     capacitacion: me.datosCapacitacion
            // });

            // axios
            //     .post(
            //         CONSTANTES.URL_RUTA_SERVICIOS + "administrador/registrar-capacitacion",
            //         registrar, {
            //             headers: {
            //                 Authorization: `Bearer ${localStorage.token}`
            //             },
            //         }
            //     )
            //     .then(function (response) {
            //         let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
            //         let color = response.data.resultado == 1 ? "success" : "danger";

            //         me.$notify({
            //             verticalAlign: 'bottom',
            //             horizontalAlign: 'right',
            //             type: color,
            //             icon: iconn,
            //             message: response.data.mensaje
            //         });
            //         me.ListarCapacitaciones();
            //         me.$refs["modal-registro-capacitacion"].hide();

            //     })
            //     .catch(function (error) {
            //         me.$notify({
            //             verticalAlign: 'bottom',
            //             horizontalAlign: 'right',
            //             type: 'danger',
            //             icon: 'emoji-frown',
            //             message: 'Error, algo salió mal.'
            //         });
            //     });
        },
        RegistrarCategoria() {
            let me = this
            let registrar = [];
            registrar.push({
                categoria: me.datosCategoria
            });

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "administrador/registrar-categoria",
                    registrar, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });
                    me.resetModalCategoria();
                    me.ObtenerTipoCapacitacion();

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },

        AnularCapacitacion(param) {
            let me = this
            let NombreCapacitacion = param.item.nombre;
            me.$bvModal
                .msgBoxConfirm(
                    "Esta seguro que desea eliminar la capacitación " +
                    NombreCapacitacion +
                    " ? ", {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "administrador/cambiar-estado-capacitacion", {
                                    estado: "1",
                                    idCapacitacion: param.item.idCapacitacion
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarCapacitaciones();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },

        RestaurarCapacitacion(param) {
            let me = this
            let NombreCapacitacion = param.item.nombre;
            me.$bvModal
                .msgBoxConfirm(
                    "Esta seguro que desea restaurar la capacitación " +
                    NombreCapacitacion +
                    " ? ", {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "administrador/cambiar-estado-capacitacion", {
                                    estado: "2",
                                    idCapacitacion: param.item.idCapacitacion
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarCapacitaciones();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },

    },
    mounted() {
        if (localStorage.usuario) {
            let me = this
            var lsUsuario = JSON.parse(localStorage.usuario)
            me.ObtenerTipoCapacitacion();
            me.ListarCapacitaciones();
        }

    }
};
</script>
