<template>
<div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <!-- Card stats -->
        <b-row>
            <b-col xl="3" md="6">
                <stats-card title="Total Clientes" type="gradient-info" :sub-title="totalRegistros" icon="people-fill" class="mb-4">

                    <template slot="footer">
                        <span class="text-success mr-2">12.18%</span>
                        <span class="text-nowrap">Este mes</span>
                    </template>
                </stats-card>
            </b-col>
            <b-col xl="3" md="6">
                <stats-card title="Activos" type="gradient-green" :sub-title="estadoActivo" icon="check-circle" class="mb-4">
                    <template slot="footer">
                        <span class="text-success mr-2">12.18%</span>
                        <span class="text-nowrap">Este mes</span>
                    </template>
                </stats-card>
            </b-col>
            <b-col xl="3" md="6">
                <stats-card title="Pendientes" type="gradient-orange" :sub-title="estadoRegistrado" icon="clock-history" class="mb-4">
                    <template slot="footer">
                        <span class="text-danger mr-2">5.72%</span>
                        <span class="text-nowrap">Este mes</span>
                    </template>
                </stats-card>

            </b-col>
            <b-col xl="3" md="6">
                <stats-card title="Suspendidos" type="gradient-red" :sub-title="estadoSuspendido" icon="info-circle-fill" class="mb-4">
                    <template slot="footer">
                        <span class="text-success mr-2">54.8%</span>
                        <span class="text-nowrap">Este mes</span>
                    </template>
                </stats-card>
            </b-col>
        </b-row>
    </base-header>

    <!--Tables-->
    <b-container fluid class="mt--7 mb-5">
        <b-row>
            <b-col xl="12">
                <card header-classes="bg-transparent">

                    <template v-slot:header>
                        <b-row align-v="center">
                            <b-col>
                                <h3 class="mb-0">Clientes</h3>
                            </b-col>
                            <b-col class="text-right">
                                <span class="btn btn-sm btn-primary" v-b-modal.modal-registro-cliente>
                                    <b-icon icon="person-plus-fill" aria-hidden="true"></b-icon> Agregar Cliente
                                </span>

                            </b-col>
                        </b-row>
                    </template>

                    <b-row>
                        <b-col lg="4">
                            <base-input v-if="!isBusy">
                                <b-input-group class="text-right" size="xl">
                                    <b-input-group-prepend is-text>
                                        <b-icon icon="search"></b-icon>
                                    </b-input-group-prepend>
                                    <b-form-input autocomplete="off" type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </base-input>
                        </b-col>
                        <b-col lg="4">
                        </b-col>
                        <b-col lg="4">
                            <base-input v-if="!isBusy">
                                <b-form-select size="xl" v-model="porPagina" id="porPaginaSelect" :options="paginaOpciones"></b-form-select>
                            </base-input>
                        </b-col>
                        <b-col lg="12">
                            <b-table :busy="isBusy" sort-icon-left show-empty small responsive outlined :items="listaClientes" :fields="camposClientes" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay clientes en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:table-busy>
                                    <div class="text-center text-info my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Cargando...</strong>
                                    </div>
                                </template>
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>

                                <template v-slot:cell(descEstado)="data">
                                    <b class="text-success" v-if="data.value=='ACTIVO'">{{ data.value }}</b>
                                    <b class="text-default" v-else-if="data.value=='REGISTRADO'">{{ data.value }}</b>
                                    <b class="text-warning" v-else-if="data.value=='INACTIVO'">{{ data.value }}</b>
                                    <b class="text-danger" v-else>{{ data.value }}</b>
                                </template>
                                <template v-slot:cell(FechaFin)="data">
                                    <b class="text-danger" v-if="data.value < validacionFecha.hoy && data.value != ''">{{data.value}} (CADUCÓ)</b>
                                    <b class="text-success" v-else-if="data.value > validacionFecha.hoy ">{{data.value}} (VIGENTE)</b>
                                    <b class="text-warning" v-else-if="data.value == validacionFecha.hoy">{{data.value}} (VIGENTE)</b>
                                    <b class="text-success" v-else></b>
                                </template>

                                <template v-slot:cell(opciones)="param">
                                    <base-button icon size="sm" type="warning" @click="ListarFiliacionCliente(param)">
                                        <span class="btn-inner--icon">
                                            <b-icon icon="toggles" aria-label="Help"></b-icon>
                                        </span>
                                        <span class="btn-inner--text">Afiliar</span>
                                    </base-button>

                                    <base-button icon size="sm" type="default" @click="ModalModificarCliente(param)">
                                        <span class="btn-inner--icon">
                                            <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                                        </span>
                                        <span class="btn-inner--text">Modificar</span>
                                    </base-button>
                                    <template v-if="param.item.estado == 1">
                                        <base-button icon size="sm" type="success" @click="RestaurarCliente(param)">
                                            <span class="btn-inner--icon">
                                                <b-icon icon="arrow-counterclockwise" aria-label="Help"></b-icon>
                                            </span>
                                            <span class="btn-inner--text">Restaurar</span>
                                        </base-button>
                                    </template>
                                    <template v-if="param.item.estado == 2 ">
                                        <base-button icon size="sm" type="danger" @click="AnularCliente(param)">
                                            <span class="btn-inner--icon">
                                                <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                                            </span>
                                            <span class="btn-inner--text">Anular</span>
                                        </base-button>
                                    </template>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                    <template v-slot:footer>
                        <b-row v-if="!isBusy">
                            <b-col lg="12">
                                <b-pagination style="justify-content:flex-end;" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                            </b-col>
                        </b-row>
                    </template>
                </card>
            </b-col>
        </b-row>
    </b-container>
    <!--End tables-->

    <!--Form modal-->
    <b-modal id="modal-registro-cliente" ref="modal-registro-cliente" title="Registrar Nuevo Cliente" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetModal">
        <b-overlay :show="show">
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(guardarCliente)">
                    <div class="pl-lg-3 pr-lg-3 ">
                        <b-row>
                            <b-col lg="12">
                               <base-input label="Número de RUC" name="número de ruc" :rules="{ required: true,numeric: true,max:11,min:11 }">
                                <b-overlay :show="cargaApi" opacity="0.5" spinner-small>
                                    <b-input-group>
                                        <b-form-input autocomplete="off" v-model="newCliente.ruc" size="sm" placeholder="Ingrese el número de RUC"></b-form-input>
                                        <b-input-group-append>
                                            <base-button size="sm" type="default" :disabled="buscarRucBtn" @click="apiSunat">
                                                <b-icon icon="search" aria-label="Help"></b-icon>
                                            </base-button>
                                                    
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-overlay>
                            </base-input>
                            </b-col>
                            <b-col lg="6">
                                <base-input label="Razón Social" name="de razon social" :rules="{required: true}" placeholder="Ingrese razón social" v-model="newCliente.razonSocial" input-classes="form-control-sm" class="mb-1" :disabled="iniNewCliente"> </base-input>
                            </b-col>
                            <b-col lg="6">
                                <base-input label="Dirección Fiscal" name="de direccíon fiscal" :rules="{required: true}" placeholder="Ingrese dirección Fiscal" v-model="newCliente.domicilioFiscal" input-classes="form-control-sm" class="mb-1" :disabled="iniNewCliente"> </base-input>
                            </b-col>
                            <b-col lg="4">
                                <base-input label="Representante Legal" name="de representante legal" :rules="{required: true}" placeholder="Ingrese representante Legal" v-model="newCliente.repLegal" input-classes="form-control-sm" class="mb-1" :disabled="iniNewCliente"> </base-input>
                            </b-col>
                            <b-col lg="4">
                                <base-input label="Documento de identidad" name="de documento de identidad" :rules="{required: true,numeric: true,max:8,min:8 }" placeholder="Ingrese documento de identidad" v-model="newCliente.dniRepLegal" input-classes="form-control-sm" class="mb-1" :disabled="iniNewCliente"> </base-input>
                            </b-col>
                            <b-col lg="4">
                                <base-input label="Teléfono" name="de teléfono" :rules="{numeric: true }" placeholder="Ingrese teléfono" v-model="newCliente.telefonoEmpresa" input-classes="form-control-sm" class="mb-1" :disabled="iniNewCliente"> </base-input>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider name="de departamento" :rules="{ required: true }" v-slot="validationContext">
                                    <b-form-group label="Departamento" class="labelx" label-for="departamento">
                                        <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" id="departamento" size="sm" value-field="idDep" :disabled="iniNewCliente" text-field="departamento" @change="ObtenerProvincia()" :options="ListaDepartamento" v-model="newCliente.Departamento">
                                            <template v-slot:first>
                                                <b-form-select-option :value="null" disabled>- Seleccione una opción -</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider name="de provincia" :rules="{ required: true }" v-slot="validationContext">
                                    <b-form-group label="Provincia" class="labelx" label-for="provincia">
                                        <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" size="sm" id="provincia" value-field="idProv" :disabled="iniNewCliente" text-field="provincias" @change="ObtenerDistrito()" :options="ListaProvincia" v-model="newCliente.Provincia">
                                            <template v-slot:first>
                                                <b-form-select-option :value="null" disabled>- Seleccione una opción -</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="4">
                                <validation-provider name="de dsitrito" :rules="{ required: true }" v-slot="validationContext">
                                    <b-form-group label="Distrito" class="labelx" label-for="distrito">
                                        <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" size="sm" id="distrito" value-field="idDist" :disabled="iniNewCliente" text-field="distrito" :options="ListaDistrito" v-model="newCliente.Distrito">
                                            <template v-slot:first>
                                                <b-form-select-option :value="null" disabled>- Seleccione una opción -</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="5">
                                <base-input label="Actividad Económica" name="de actividad económica" placeholder="Ingrese actividad económica" v-model="newCliente.ActividadEconomica" input-classes="form-control-sm" class="mb-1" :disabled="iniNewCliente"> </base-input>
                            </b-col>
                            <b-col lg="12 text-right">
                                <b-button class="mb-2" size="sm" type="submit" variant="success">
                                    Registrar Cliente
                                </b-button>
                                <b-button class="mb-2" size="sm" variant="danger" @click="$bvModal.hide('modal-registro-cliente')">Cerrar</b-button>

                            </b-col>
                        </b-row>
                    </div>
                </b-form>
            </validation-observer>
        </b-overlay>
    </b-modal>

    <b-modal id="modal-afiliar-cliente" ref="modal-afiliar-cliente" title="Afiliar Cliente" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop @show="resetModalfiliacion">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(grabarFiliacion)">
                <div class="pl-lg-3 pr-lg-3 ">
                    <b-row>
                        <b-col lg="4">
                            <base-input label="Número de RUC" name="de número de ruc" placeholder="Número de RUC" disabled v-model="ventaCliente.ruc" input-classes="form-control-sm" class="mb-1"> </base-input>
                        </b-col>
                        <b-col lg="8">
                            <base-input label="Razón Social" name="de razón social" placeholder="Razón Social" disabled v-model="ventaCliente.razonsocial" input-classes="form-control-sm" class="mb-1"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Fecha Venta" name="de fecha venta" placeholder="Fecha Venta" disabled v-model="ventaCliente.fechaVenta" input-classes="form-control-sm" class="mb-1"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Fecha Inicio de Contrato" type="date" name="de fecha inicio contrato" placeholder="Fecha Venta" :rules="{required: true }" v-model="ventaCliente.FecIniContrato" :min="validacionFecha.ultimofin" :max="ventaCliente.FecFinContrato" input-classes="form-control-sm" class="mb-1"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Fecha Fin de Contrato" type="date" name="de fecha fin contrato" placeholder="Fecha Venta" :rules="{required: true }" v-model="ventaCliente.FecFinContrato" :disabled="validacionFecha.activado" ref="beforeTarget" :min="ventaCliente.FecIniContrato" input-classes="form-control-sm" class="mb-1"> </base-input>
                        </b-col>
                        <b-col lg="4" style="margin: auto;">
                            <validation-provider name="de cantidad de trabajadores" :rules="{required: true }" v-slot="validationContext">
                                <b-form-group label="Cantidad de Trabajadores" class="labelx" label-for="cantrabaj">
                                    <b-form-select :state="getValidationState(validationContext)" aria-describedby="input-1-live-feedback" id="estadoUsuario" :options="comboRangoPersonas" value-field="idRango" text-field="descripcion" v-model="ventaCliente.rango" size="sm">
                                        <template v-slot:first>
                                            <b-form-select-option :value="null" disabled>- Seleccione un rango -</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="3">
                            <base-input label="Costo" name="de costo" :rules="{numeric:true,required:true }" input-classes="form-control-sm" class="mb-1" placeholder="Ingrese Costo (S/.)" v-model="ventaCliente.precio"> </base-input>
                        </b-col>
                        <b-col lg="5">
                        </b-col>
                        <b-col lg="12 text-right">
                            <b-button class="mb-2" size="sm" type="submit" variant="success">
                                Afiliar Cliente
                            </b-button>
                            <b-button class="mb-2" size="sm" variant="danger" @click="$bvModal.hide('modal-afiliar-cliente')">Cerrar</b-button>
                        </b-col>
                        <b-col lg="12">
                            <b-table :items="listaFiliacion" class="mt-2" outlined :fields="camposFiliacion" responsive style="font-size: 13px;" show-empty empty-text="Este cliente aún no tiene filiación.">

                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>

                                <template v-slot:cell(Vermas)="row">
                                    <b-button variant="default" size="sm" @click="row.toggleDetails" class="mr-2">
                                        {{ row.detailsShowing ? 'Ocultar' : 'Mostrar'}} Detalles
                                    </b-button>
                                </template>
                                <template v-slot:row-details="row">
                                    <b-card>
                                        <b-row class="mb-2">
                                            <b-col sm="4" class="text-sm-right"><b>Monto:</b></b-col>
                                            <b-col>S/. {{ row.item.monto }}</b-col>
                                        </b-row>
                                        <b-row class="mb-2">
                                            <b-col sm="4" class="text-sm-right"><b>Plan seleccionado:</b></b-col>
                                            <b-col>{{ row.item.rango }}</b-col>
                                        </b-row>

                                    </b-card>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>

    <b-modal id="modal-modificar-cliente" ref="modal-modificar-cliente" title="Modificar datos del Cliente" size="lg" border-variant="info" no-close-on-esc hide-footer no-close-on-backdrop>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(ModificarCliente)">
                <div class="pl-lg-3 pr-lg-3 ">
                    <b-row>
                        <b-col lg="6">
                            <base-input label="Número de RUC" name="de número de ruc " :rules="{ required: true }" placeholder="Ingrese número de ruc" input-classes="form-control-sm" disabled v-model="modifCliente.ruc"> </base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input label="Razón Social" name="de razón social " :rules="{ required: true }" placeholder="Ingrese razón social" input-classes="form-control-sm" disabled v-model="modifCliente.razonSocial"> </base-input>
                        </b-col>
                        <b-col lg="7">
                            <base-input label="Dirección" name="de dirección " :rules="{ required: true }" placeholder="Ingrese direccíon" input-classes="form-control-sm" v-model="modifCliente.domicilioFiscal"> </base-input>
                        </b-col>
                        <b-col lg="5">
                            <base-input label="Representante legal" name="de representante legal " :rules="{ required: true }" placeholder="Ingrese representante legal" input-classes="form-control-sm" v-model="modifCliente.repLegal"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="DNI del representate legal" name="de representante legal " :rules="{ required: true,numeric:true }" placeholder="Ingrese dni del representante legal" input-classes="form-control-sm" v-model="modifCliente.dniRepLegal"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Teléfono 1" name="de teléfono 1 " :rules="{ numeric:true }" placeholder="Ingrese teléfono 1" input-classes="form-control-sm" v-model="modifCliente.telefono1"> </base-input>
                        </b-col>
                        <b-col lg="4">
                            <base-input label="Teléfono 2" name="de teléfono 2 " :rules="{ numeric:true }" placeholder="Ingrese teléfono 2" input-classes="form-control-sm" v-model="modifCliente.telefono2"> </base-input>
                        </b-col>
                        <b-col lg="12 text-right">
                            <b-button class="mb-2" size="sm" type="submit" variant="success">
                                Actualizar datos
                            </b-button>
                            <b-button class="mb-2" size="sm" variant="danger" @click="$bvModal.hide('modal-modificar-cliente')">Cerrar</b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

// Components
import StatsCard from '@/components/Cards/StatsCard';

export default {
    components: {
        StatsCard,
    },
    data() {
        return {
            buscarRucBtn: true,
            validacionFecha: {
                hoy: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
                //     ultimoinicio: "",
                ultimofin: "",
                activado: true
            },
            iniNewCliente: true,
            show: false,
            cargaApi: false,
            newCliente: {
                ruc: "",
                razonSocial: "",
                domicilioFiscal: "",
                repLegal: null,
                dniRepLegal: "",
                telefonoEmpresa: "",
                usuario: "",
                Provincia: null,
                Distrito: null,
                Departamento: null,
                ActividadEconomica: "",
            },
            modifCliente: {
                idCliente: "",
                usuario: "admin",
                ruc: "",
                razonSocial: "",
                domicilioFiscal: "",
                repLegal: null,
                dniRepLegal: "",
                telefono1: "",
                telefono2: "",
            },
            estadoRegistrado: "",
            estadoActivo: "",
            estadoSuspendido: "",
            estadoBaja: "",
            totalRegistros: "",
            listaClientes: [],
            ListaDistrito: [],
            ListaProvincia: [],
            ListaDepartamento: [],
            listaFiliacion: [],
            comboRangoPersonas: [],
            isBusy: false,
            filtro: {
                ruc: "",
                razonsocial: "",
                estadoUsuario: null,
            },
            ventaCliente: {
                ruc: "",
                razonsocial: "",
                fechaVenta: "",
                FecIniContrato: "",
                FecFinContrato: "",
                rango: null,
                precio: "",
                usuario: "",
                idCliente: "",
            },
            camposFiliacion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "fechaventa",
                    label: "Fecha de Venta",
                    class: "text-center",
                },
                {
                    key: "fechainicontrato",
                    label: "Fecha Inicio",
                    class: "text-center",
                },
                {
                    key: "fechafincontrato",
                    label: "Fecha Fin",
                    class: "text-center",
                },
                {
                    key: "Vermas",
                    label: "Ver detalle",
                    class: "text-center",
                },
            ],

            camposClientes: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "ruc",
                    label: "RUC",
                    class: "text-center"
                },
                {
                    key: "razonSocial",
                    label: "Razón Social",
                    class: "text-center"
                },
                {
                    key: "telefono1",
                    label: "Teléfono",
                    class: "text-center"
                },
                {
                    key: "repLegal",
                    label: "Rep. Legal",
                    class: "text-center"
                },
                {
                    key: "FechaFin",
                    label: "Fecha fin del plan",
                    class: "text-center"
                },
                {
                    key: "descEstado",
                    label: "Estado",
                    class: "text-center",
                },
                {
                    key: "cantPersonasRegistradas",
                    label: "N° Responsables",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    class: "text-left",
                },
            ],
            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, {
                value: 99999,
                text: "Mostrar todo"
            }],

            filter: null,
            filterOn: [],
        };
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        AnularCliente(param) {
            let me = this
            me.$bvModal
                .msgBoxConfirm(
                    'Esta seguro que desea desactivar al cliente "' +
                    param.item.razonSocial +
                    '" ? ', {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "master/desactivar-cliente", {
                                    idCliente: param.item.idCliente,
                                    Estado: "1"
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarClientes();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },
        RestaurarCliente(param) {
            let me = this
            me.$bvModal
                .msgBoxConfirm(
                    'Esta seguro que desea activar al cliente "' +
                    param.item.razonSocial +
                    '" ? ', {
                        title: "Por favor, confirmar",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((r) => {
                    if (r == true) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + "master/activar-cliente", {
                                    idCliente: param.item.idCliente,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`
                                    },
                                }
                            ).then((response) => {
                                if (response) {
                                    me.ListarClientes();
                                }
                            })
                            .catch(function (error) {
                                me.$notify({
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'right',
                                    type: 'danger',
                                    icon: 'emoji-frown',
                                    message: 'Error, algo salió mal.'
                                });
                            });
                    }
                });
        },
        guardarCliente() {
            let me = this;
            let registrarCliente = [];
            registrarCliente.push({
                cliente: me.newCliente
            });
            me.show = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-cliente",
                    registrarCliente, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.show = false;
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });
                    me.ListarClientes();
                    me.$refs["modal-registro-cliente"].hide();
                    me.$nextTick(() => {
                        me.$refs.observer.reset();
                    });
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },

        apiSunat() {
            let me = this;
            let mi_token = "76afdc4b32381ff72ff7a4d827f7856bb850e6228adc9e9f6f1c74e114510f22";
            me.cargaApi = true;
            me.iniNewCliente = true;
            axios
                .get("https://apiperu.dev/api/ruc/" + me.newCliente.ruc, {
                    headers: {
                        Authorization: `Bearer ${mi_token}`
                    },
                    responseType: "json",
                })
                .then((response) => {
                    if (response.data.data) {
                        me.newCliente.razonSocial = response.data.data.nombre_o_razon_social;
                        me.newCliente.domicilioFiscal = response.data.data.direccion_completa;
                        me.newCliente.Departamento = response.data.data.ubigeo[0]
                        me.ObtenerProvincia();
                        me.newCliente.Provincia = response.data.data.ubigeo[1]
                        me.ObtenerDistrito();
                        me.newCliente.Distrito = response.data.data.ubigeo[2]
                    } else {
                        me.$notify({
                            verticalAlign: 'bottom',
                            horizontalAlign: 'right',
                            type: 'danger',
                            icon: 'emoji-frown',
                            message: response.data.message
                        });
                    }
                    me.iniNewCliente = false;
                    me.cargaApi = false;
                })
                .catch((error) => {
                    me.iniNewCliente = false;
                    me.cargaApi = false;
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        toggleBusy() {
            let me = this
            me.isBusy = !me.isBusy;
        },
        resetModal() {
            let me = this
            me.newCliente.ruc = "";
            me.newCliente.razonSocial = "";
            me.newCliente.domicilioFiscal = "";
            me.newCliente.repLegal = null;
            me.newCliente.dniRepLegal = "";
            me.newCliente.telefonoEmpresa = "";
            me.newCliente.Departamento = null;
            me.newCliente.ActividadEconomica = "";
            me.newCliente.Distrito = null;
            me.newCliente.Provincia = null;
            me.iniNewCliente = true;
            me.cargaApi = false;
        },
        ObtenerDepartamento() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-departamento")
                .then(function (response) {
                    me.ListaDepartamento = response.data;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ObtenerProvincia() {
            let me = this;
            (me.newCliente.Provincia = null),
            (me.newCliente.Distrito = null),
            (me.ListaDistrito = []),
            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-provincia", {
                    params: {
                        idDepartamento: me.newCliente.Departamento,
                    },
                })
                .then(function (response) {
                    me.ListaProvincia = response.data;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ObtenerDistrito() {
            let me = this;
            (me.newCliente.Distrito = null),
            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-distrito", {
                    params: {
                        idProvincia: me.newCliente.Provincia,
                    },
                })
                .then(function (response) {
                    me.ListaDistrito = response.data;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ListarClientes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/listar-clientes", {
                    estado: 1,
                })
                .then(function (response) {
                    me.listaClientes = response.data;
                    me.filasTotales = me.listaClientes.length
                    me.estadisticasClientes();
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ModalModificarCliente(param) {
            let me = this;
            me.modifCliente.idCliente = param ? param.item.idCliente : null || me.modifCliente.idCliente;
            me.modifCliente.ruc = param ? param.item.ruc : null || me.modifCliente.ruc;
            me.modifCliente.razonSocial = param ? param.item.razonSocial : null || me.modifCliente.razonSocial;
            me.modifCliente.repLegal = param ? param.item.repLegal : null || me.modifCliente.repLegal;
            me.modifCliente.dniRepLegal = param ? param.item.dniRep : null || me.modifCliente.dniRepLegal;
            me.modifCliente.domicilioFiscal = param ? param.item.direccion : null || me.modifCliente.domicilioFiscal;
            me.modifCliente.telefono1 = param ? param.item.telefono1 : null || me.modifCliente.telefono1;
            me.modifCliente.telefono2 = param ? param.item.telefono2 : null || me.modifCliente.telefono2;

            me.$refs["modal-modificar-cliente"].show();
        },
        resetModalfiliacion() {
            let me = this;
            //   me.validacionFecha.ultimoinicio = ""
            me.ventaCliente.FecIniContrato = ""
            me.ventaCliente.FecFinContrato = ""
            me.ventaCliente.rango = null
            me.ventaCliente.precio = ""
            me.validacionFecha.activado = true
            me.validacionFecha.ultimofin = ""

        },
        ModificarCliente() {

            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/actualizar-cliente",
                    me.modifCliente, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });

                    me.ListarClientes();
                    me.$refs["modal-modificar-cliente"].hide();
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        estadisticasClientes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/estadisticas-clientes")
                .then(function (response) {
                    me.estadoRegistrado = String(response.data[0].cantPendientes);
                    me.totalRegistros = String(response.data[0].total);
                    me.estadoActivo = String(response.data[0].cantActivos);
                    me.estadoSuspendido = String(response.data[0].cantSuspendido);
                    me.estadoBaja = String(response.data[0].cantBaja);
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        grabarFiliacion() {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-filiacion",
                    me.ventaCliente, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });

                    me.ListarClientes();
                    me.resetModalfiliacion();
                   
                    if (response.data.resultado == 1) {
                        me.ListarFiliacionCliente();
                    }
                    me.$nextTick(() => {
                        me.$refs.observer.reset();
                    });

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        obtenerRangoPersonas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-rango-personas")
                .then(function (response) {
                    me.comboRangoPersonas = response.data;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        ListarFiliacionCliente(param) {
            let me = this;
            let vIdCliente = param ?
                param.item.idCliente :
                null || me.ventaCliente.idCliente;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/buscar-filiacion-cliente", {
                        params: {
                            idCliente: vIdCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaFiliacion = response.data;
            

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });

            me.ventaCliente.ruc = param ?
                param.item.ruc :
                null || me.ventaCliente.ruc;
            me.ventaCliente.razonsocial = param ?
                param.item.razonSocial :
                null || me.ventaCliente.razonsocial;
            me.ventaCliente.idCliente = param ?
                param.item.idCliente :
                null || me.ventaCliente.idCliente;
            me.ventaCliente.fechaVenta = me.validacionFecha.hoy
            me.ObtenerUltimaFechaVenta();
            me.$refs["modal-afiliar-cliente"].show();

        },

        ObtenerUltimaFechaVenta() {
            let me = this;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/obtener-UltimaFechaVenta", {
                    params: {
                        idCliente: me.ventaCliente.idCliente
                    },
                })
                .then(function (response) {
                    if (response.data.length == 0) {
                        me.validacionFecha.ultimofin = me.validacionFecha.hoy
                    } else {
                        me.validacionFecha.ultimofin = response.data[0].fin;
                    }
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal'
                    });
                });
        },

    },
    watch: {
        'ventaCliente.FecIniContrato': function (val) {
            if (val) {
                let me = this
                me.validacionFecha.activado = false;
            }
        },
        'newCliente.ruc': function (val) {
           if (val.length == 11){
               this.buscarRucBtn = false
           }
           else{
               this.buscarRucBtn = true
           }
        }
        
    },
    mounted() {
        if (localStorage.usuario) {
            let me = this
            var lsUsuario = JSON.parse(localStorage.usuario);
            me.estadisticasClientes();
            me.ListarClientes();
            me.ObtenerDepartamento();
            me.obtenerRangoPersonas();
        }
    }
};
</script>
