<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
        </b-row>
    </base-header>
    <!--End head-->

</div>
</template>

<script>
export default {
    name: "routerpage",
    components: {

    },
    data() {
        return {

        };
    },
    mounted() {
        if (localStorage.usuario) {
            let me = this;
            var lsUsuario = JSON.parse(localStorage.usuario)
            if (lsUsuario.idPerfil == "1") {
                me.$router.push({
                    name: "control clientes",
                });
            }
            if (lsUsuario.idPerfil == "2") {
                me.$router.push({
                    name: "control capacitaciones",
                });
            }
            if (lsUsuario.idPerfil == "3") {
                me.$router.push({
                    name: "panel responsable",
                });
            }
            if (lsUsuario.idPerfil == "4") {
                me.$router.push({
                    name: "panel trabajador",
                });
            }
        }
    }
};
</script>
