<template>
<div>
    <!-- head-->
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-warning">
        <b-row>
            <b-col xl="12" class="order-xl-2 ">
                <b-overlay :show="cargarInformacionEvaluacion" rounded="lg" opacity="0.5">
                    <template v-slot:overlay>
                        <div class="d-flex align-items-center">
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                            <b-spinner type="grow" variant="dark"></b-spinner>
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                        </div>
                    </template>
                    <card header-classes="bg-transparent">
                        <template v-slot:header>
                            <b-row align-v="center" slot="header">
                                <b-col>
                                    <h3 class="mb-0">Evaluación</h3>
                                </b-col>
                                <b-col class="text-right">
                                    <template v-if="estadosEvaluacion==2">
                                        <base-button icon size="sm" type="warning">
                                            <b-spinner variant="light" small></b-spinner>
                                            En curso
                                        </base-button>
                                    </template>
                                    <template v-else-if="estadosEvaluacion==3 ">
                                        <base-button icon size="sm" type="danger">
                                            <b-icon icon="back" aria-hidden="true"></b-icon> Finalizado
                                        </base-button>
                                    </template>
                                </b-col>
                            </b-row>
                        </template>
                        <b-row>
                            <b-col lg="12" v-if="estadosEvaluacion==3 ">
                                <b-list-group flush class="list">
                                    <b-list-group-item>
                                        <b-row align-v="center">
                                            <b-col md="auto" sm="12 mb-3">
                                                <b-icon icon="file-earmark-binary-fill"> </b-icon>
                                            </b-col>
                                            <b-col sm="12 mb-3" lg="4" xl="4">
                                                <h5 class="mb-0">Nombre de la evaluación:</h5>
                                                <small>{{datosEvaluacion.nombre }}</small>
                                            </b-col>

                                            <b-col sm="12 mb-3" lg="4" xl="4">
                                                <h5 class="mb-0">Número de preguntas:</h5>
                                                <small>{{datosEvaluacion.n_preguntas }}</small>
                                            </b-col>
                                            <b-col sm="12" lg="3 mb-3" xl="3">
                                                <h5 class="mb-0">Número de intentos:</h5>
                                                <small>{{datosEvaluacionF.n_intento}}</small>
                                            </b-col>
                                        </b-row>
                                    </b-list-group-item>
                                    <b-list-group-item>
                                        <b-row align-v="center">
                                            <b-col md="auto" sm="12 mb-3">
                                                <b-icon icon="award-fill"> </b-icon>
                                            </b-col>
                                            <b-col sm="12 mb-3" lg="4" xl="4">
                                                <h5 class="mb-0">Número de respuestas correctas:</h5>
                                                <small>{{datosEvaluacionF.n_correctas}}</small>
                                            </b-col>
                                            <b-col sm="12 mb-3" lg="4 " xl="4">
                                                <h5 class="mb-0">Número de respuestas incorrectas:</h5>
                                                <small>{{datosEvaluacionF.n_incorrectas}}</small>
                                            </b-col>
                                            <b-col sm="12" lg="3 mb-3" xl="3">
                                                <h5 class="mb-0">% de Aciertos:</h5>
                                                <small v-if="datosEvaluacionF.porc_aciertos <= 33" class="text-danger">{{datosEvaluacionF.porc_aciertos}}%</small>
                                                <small v-else-if="datosEvaluacionF.porc_aciertos <= 66" class="text-primary">{{datosEvaluacionF.porc_aciertos}}%</small>
                                                <small v-else-if="datosEvaluacionF.porc_aciertos <= 100" class="text-success">{{datosEvaluacionF.porc_aciertos}}%</small>
                                            </b-col>
                                        </b-row>
                                    </b-list-group-item>

                                </b-list-group>
                            </b-col>

                            <b-col lg="12">
                                <div class="panel-body">
                                    <!--  3 = finalizado-->
                                    <div v-if="estadosEvaluacion == 1 || estadosEvaluacion == 4 || estadosEvaluacion == 2">
                                        <b-table-simple responsive hover small outlined>
                                            <b-thead head-variant="dark">
                                                <b-tr>
                                                    <b-th style="color:white" class="text-center">{{datosEvaluacion.nombre}}</b-th>
                                                </b-tr>
                                            </b-thead>
                                        </b-table-simple>
                                    </div>

                                    <b-list-group v-if="estadosEvaluacion == 1 || estadosEvaluacion == 4">
                                        <b-list-group-item>
                                            <b-row align-v="center">
                                                <b-col class="align-center text-center">
                                                    <pre></pre>
                                                    <base-button v-if="estadosEvaluacion == 1" variant="default" @click="empezarEvaluacion">
                                                        <b-icon icon="play-fill" aria-label="Help"></b-icon> Empezar evaluación
                                                    </base-button>
                                                    <base-button v-if="estadosEvaluacion == 4" variant="default" @click="empezarEvaluacion">
                                                        <b-icon icon="play-fill" aria-label="Help"></b-icon> Reintentar evaluación
                                                    </base-button>
                                                    <pre></pre>
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-list-group>

                                    <div v-else-if="estadosEvaluacion==2">
                                        <b-list-group>
                                            <b-list-group-item action v-bind:key="items.idPregunta" v-for="(items, indexv) in listaPreguntas">
                                                <b-row align-v="center">
                                                    <b-col class="ml--2">
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <div>
                                                                <h4 class="mb-0 text-sm">{{items.n_pregunta}}. {{items.enunciado}}</h4>
                                                            </div>
                                                        </div>
                                                        <pre></pre>
                                                        <b-form-radio-group plain size="sm" small v-model="abc[indexv]" :options="
                                                        [ {value: 'a', html: `<p class='text-sm mb-0'><b>a)</b> ${items.opcionA}</p>`,  },
                                                        {  value: 'b',html: `<p class='text-sm mb-0'><b>b)</b> ${items.opcionB}</p>`,  },
                                                        {  value: 'c',html: `<p class='text-sm mb-0'><b>c)</b> ${items.opcionC}</p>` } ]" stacked>
                                                        </b-form-radio-group>
                                                    </b-col>
                                                </b-row>
                                            </b-list-group-item>
                                            <b-list-group-item action>
                                                <b-row align-v="center">
                                                    <b-col class="ml--2">
                                                        <div class="d-flex justify-content-between align-items-right">
                                                            <div>
                                                                <h4 class="mb-0 text-sm text-muted text-right">Total Preguntas : {{abc.filter(x => !!x).length}}/{{datosEvaluacion.n_preguntas}}</h4>
                                                            </div>
                                                        </div>
                                                        <span></span>
                                                    </b-col>
                                                </b-row>
                                            </b-list-group-item>
                                        </b-list-group>

                                        <div class="text-right">
                                            <base-button v-if="isReintento == 1" class="mt-3" icon size="lg" type="success" @click="ReenviarEvaluacion">
                                                <b-overlay :show="cargarEnviar" opacity="0.4">
                                                    <span class="btn-inner--icon">
                                                        <b-icon icon="shift-fill" aria-label="Help"></b-icon>
                                                    </span>
                                                    <span class="btn-inner--text">Reenviar respuestas</span>
                                                </b-overlay>
                                            </base-button>

                                            <base-button v-else class="mt-3" icon size="lg" type="success" @click="EnviarEvaluacion">
                                                <b-overlay :show="cargarEnviar" opacity="0.4">
                                                    <span class="btn-inner--icon">
                                                        <b-icon icon="shift-fill" aria-label="Help"></b-icon>
                                                    </span>
                                                    <span class="btn-inner--text">Enviar respuestas</span>
                                                </b-overlay>
                                            </base-button>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </card>
                </b-overlay>
            </b-col>
        </b-row>
    </base-header>
    <!--End head-->

    <!--ontainer-->
    <b-container fluid class="mt--8 mb-9">
        <div class="mt-4">
            <b-row>
                <b-col xl="12">

                </b-col>
            </b-row>
        </div>
    </b-container>
    <!--End container-->
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    name: "ContenidoEvaluacion",
    components: {

    },
    data() {
        return {
            listaRespuestas: [],
            listaPreguntas: [],
            abc: [],

            estadosEvaluacion: '',
            isReintento: '',

            cargarInformacionEvaluacion: false,
            cargarPreguntas: false,
            cargarEnviar: false,

            idPersona: "",
            idPersonaCapacitacion: "",
            idCliente: "",

            datosPregunta: {
                idevaluacion: "",
                enunciado: "",
                tipoPregunta: 1,
                preguntaA: "",
                preguntaB: "",
                preguntaC: "",
                respuesta: null
            },
            datosParams: {
                nombre: "",
                idCapacitacionEvaluacion: ""
            },
            datosEvaluacion: {
                id_evaluacion: "",
                nombre: "",
                descripcion: "",
                n_preguntas: ""
            },
            datosEvaluacionF: {
                id_persona_evaluacion: "",
                estado: "",
                fecha_creacion: "",
                n_correctas: "",
                n_incorrectas: "",
                n_intento: 1,
                porc_aciertos: 0,
            },
        };
    },
    methods: {
        ReenviarEvaluacion() {
            let me = this
            if (me.abc.filter(x => !!x).length < me.datosEvaluacion.n_preguntas) {
                me.$notify({
                    verticalAlign: 'top',
                    horizontalAlign: 'right',
                    type: 'danger',
                    icon: 'emoji-frown',
                    message: 'Falta marcar todas las opciones.'
                });
            } else {
                me.listaRespuestas = []
                for (let i = 0; i < me.abc.length; i++) {
                    let valor
                    if (me.abc[i] == me.listaPreguntas[i].respuesta) {
                        valor = true
                    } else {
                        valor = false
                    }
                    me.listaRespuestas.push({
                        'opcions': me.abc[i],
                        'id_pregunta': me.listaPreguntas[i].idPregunta,
                        'v': valor
                    })
                }
                me.ActualizarEvaluacion();
            }
        },
        ActualizarEvaluacion() {
            let me = this
            me.cargarEnviar = true
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "trabajador/actualizar-evaluacion-persona", {
                        id_capacitacion_evaluacion: me.datosParams.idCapacitacionEvaluacion,
                        id_persona_evaluacion: me.datosEvaluacionF.id_persona_evaluacion,
                        id_persona_capacitacion: me.idPersonaCapacitacion,
                        id_persona: me.idPersona,
                        n_intento: me.datosEvaluacionF.n_intento + 1,
                        respuestas: me.listaRespuestas,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });

                    me.listaRespuestas = []
                    me.abc = []
                    me.cargarDatosEvaluacionTrabajador();
                    me.cargarEnviar = false

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },
        EnviarEvaluacion() {
            let me = this
            if (me.abc.filter(x => !!x).length < me.datosEvaluacion.n_preguntas) {
                me.$notify({
                    verticalAlign: 'top',
                    horizontalAlign: 'right',
                    type: 'danger',
                    icon: 'emoji-frown',
                    message: 'Falta marcar todas las opciones.'
                });
            } else {
                me.listaRespuestas = []
                for (let i = 0; i < me.abc.length; i++) {
                    let valor
                    if (me.abc[i] == me.listaPreguntas[i].respuesta) {
                        valor = true
                    } else {
                        valor = false
                    }
                    me.listaRespuestas.push({
                        'opcions': me.abc[i],
                        'id_pregunta': me.listaPreguntas[i].idPregunta,
                        'v': valor
                    })
                }
                me.RegistroEvaluacionPersona();
            }
        },

        RegistroEvaluacionPersona() {
            let me = this
            me.cargarEnviar = true
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "trabajador/registrar-evaluacion-persona", {
                        id_capacitacion_evaluacion: me.datosParams.idCapacitacionEvaluacion,
                        id_persona_capacitacion: me.idPersonaCapacitacion,
                        id_persona: me.idPersona,
                        n_intento: me.datosEvaluacionF.n_intento,
                        respuestas: me.listaRespuestas,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let iconn = response.data.resultado == 1 ? "emoji-smile" : "emoji-frown";
                    let color = response.data.resultado == 1 ? "success" : "danger";

                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: color,
                        icon: iconn,
                        message: response.data.mensaje
                    });

                    me.listaRespuestas = []
                    me.abc = []
                    me.cargarDatosEvaluacionTrabajador();
                    me.cargarEnviar = false

                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.' + error
                    });
                });
        },

        empezarEvaluacion() {
            let me = this
            me.estadosEvaluacion = 2;
        },

        cargarDatosEvaluacionTrabajador() {
            let me = this;
            me.cargarInformacionEvaluacion = true,
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS +
                    "trabajador/cargar-datos-evaluacion", {
                        params: {
                            idCapacitacionEvaluacion: me.datosParams.idCapacitacionEvaluacion,
                            idCliente: me.idCliente,
                            idPersona: me.idPersona,
                        }
                    }
                )
                .then(function (response) {
                    if (response) {
                        if (response.data[0].n_intento) {
                            if (response.data[0].estado == 2) {
                                me.datosEvaluacionF.estado = response.data[0].estado
                                me.datosEvaluacionF.n_correctas = response.data[0].n_correctas
                                me.datosEvaluacionF.n_incorrectas = response.data[0].n_incorrectas
                                me.datosEvaluacionF.n_intento = response.data[0].n_intento
                                me.datosEvaluacionF.fecha_creacion = response.data[0].fecha_creacion

                                me.datosEvaluacion.id_evaluacion = response.data[0].id_evaluacion
                                me.datosEvaluacion.nombre = response.data[0].nombre
                                me.datosEvaluacion.descripcion = response.data[0].descripcion
                                me.datosEvaluacion.n_preguntas = response.data[0].n_preguntas
                                me.estadosEvaluacion = 3

                                me.datosEvaluacionF.porc_aciertos = ((response.data[0].n_correctas * 100) / response.data[0].n_preguntas).toFixed(2)
                                //si hay un chance
                            } else if (response.data[0].estado == 1) {
                                me.datosEvaluacionF.id_persona_evaluacion = response.data[0].id_persona_evaluacion
                                me.datosEvaluacionF.estado = response.data[0].estado
                                me.datosEvaluacionF.n_correctas = response.data[0].n_correctas
                                me.datosEvaluacionF.n_incorrectas = response.data[0].n_incorrectas
                                me.datosEvaluacionF.n_intento = response.data[0].n_intento
                                me.datosEvaluacionF.fecha_creacion = response.data[0].fecha_creacion

                                me.datosEvaluacion.id_evaluacion = response.data[0].id_evaluacion
                                me.datosEvaluacion.nombre = response.data[0].nombre
                                me.datosEvaluacion.descripcion = response.data[0].descripcion
                                me.datosEvaluacion.n_preguntas = response.data[0].n_preguntas
                                me.estadosEvaluacion = 4
                                me.isReintento = 1

                                me.datosEvaluacionF.porc_aciertos = ((response.data[0].n_correctas * 100) / response.data[0].n_preguntas).toFixed(2)
                                me.ListarPreguntas();
                            }

                        } else {
                            me.datosEvaluacion.id_evaluacion = response.data[0].id_evaluacion
                            me.datosEvaluacion.nombre = response.data[0].nombre
                            me.datosEvaluacion.descripcion = response.data[0].descripcion
                            me.datosEvaluacion.n_preguntas = response.data[0].n_preguntas
                            me.estadosEvaluacion = 1
                            me.ListarPreguntas();
                        }
                        me.cargarInformacionEvaluacion = false
                    }
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                    me.cargarInformacionEvaluacion = true
                });
        },

        ListarPreguntas() {
            let me = this;
            me.cargarPreguntas = true;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "trabajador/listar-preguntas", {
                        params: {
                            idEvaluacion: me.datosEvaluacion.id_evaluacion,
                            n_preguntas: me.datosEvaluacion.n_preguntas
                        },
                    }
                ).then(function (response) {
                    var i = 1
                    response.data.forEach((a) => {
                        a.n_pregunta = i++
                    })

                    me.listaPreguntas = response.data
                    me.cargarPreguntas = false;
                })
                .catch(function (error) {
                    me.$notify({
                        verticalAlign: 'bottom',
                        horizontalAlign: 'right',
                        type: 'danger',
                        icon: 'emoji-frown',
                        message: 'Error, algo salió mal.'
                    });
                });
        },

    },
    watch: {

    },
    filters: {
        truncate: function (text, length, suffix) {
            return text.substring(0, length) + suffix;
        },
    },
    mounted() {
        if (localStorage.usuario) {
            let me = this;
            var lsUsuario = JSON.parse(localStorage.usuario)
            me.idCliente = lsUsuario.nIdCliente
            me.idPersona = lsUsuario.idPersona
            me.idPersonaCapacitacion = me.$route.params.idcaper
            me.datosParams.idCapacitacionEvaluacion = me.$route.params.id
            me.datosParams.nombre = me.$route.params.nombre
            me.cargarDatosEvaluacionTrabajador();
        }

    }
};
</script>
